import React, { Component, useEffect, useState } from 'react';
import './Settings.css';
import withAuth from '../components/Login/withAuth';
import { crudAlertControl } from '../Functions';
import LocationAlert from '../components/LocationAlert';
import { useTranslation } from 'react-i18next';

const Settings = () => {
    const { t } = useTranslation();
    const [loginQR, setLoginQR] = useState([]);
    const [openalarm_min, setOpenalarm_min] = useState(0);
    const [nightnurseid, setNightnurseid] = useState([]);
    const [nightnurseclientid, setNightnurseclientid] = useState([]);
    const [nightnursesecret, setNightnursesecret] = useState([]);
    const [mbatemplates, setMbatemplates] = useState([]);
    const [alarmEmployees, setAlarmEmployees] = useState([]);
    const [serviceleveltime, setServiceleveltime] = useState([]);

    const setAlertTime = async (value) => {
        const settings = await import("../data/settings.js");
        settings.updateAlert(value).then(data => {
            crudAlertControl('update', data);
        });
    }

    const setNightNurseID = async (value) => {
        const settings = await import("../data/settings.js");
        settings.updateNightNurseID(value).then(data => {
            crudAlertControl('update', data);
        });
    }

    const setNightNurseClientID = async (value) => {
        const settings = await import("../data/settings.js");
        settings.updateNightNurseClientID(value).then(data => {
            crudAlertControl('update', data);
        });
    }

    const setNightNurseSecret = async (value) => {
        const settings = await import("../data/settings.js");
        settings.updateNightNurseSecret(value).then(data => {
            crudAlertControl('update', data);
        });
    }

    const switchLoginQR = async (event) => {
        var value = event.target.checked === true ? "1" : "0";
        const settings = await import("../data/settings.js");
        settings.switchQR(value).then(data => {
            crudAlertControl('update', data);
        })
    }

    const setMbaTemplates = async (event) => {
        var value = event.target.checked === true ? "1" : "0";
        const settings = await import("../data/settings.js");
        settings.updateMbaTemplates(value).then(data => {
            crudAlertControl('update', data);
        })
    }

    const setEmployees = async (event) => {
        var value = event.target.checked === true ? "1" : "0";
        const settings = await import("../data/settings.js");
        settings.updateAlarmEmployees(value).then(data => {
            crudAlertControl('update', data);
        })
    }

    const setServicelevelTime = async (value) => {
        const settings = await import("../data/settings.js");
        settings.updateServiceLevelTime(value).then(data => {
            crudAlertControl('update', data);
        });
    }

    const getData = async () => {
        const { getAlert, QRLogin, getNightNurseID, getNightNurseClientID, getNightNurseSecret, getServiceLevelTime, getMbaTemplates, getAlarmEmployees } = await import("../data/settings.js");

        const [alertData, loginData, nurseIdData, clientIdData, secretData, servicelevelData, mbaData, employees] = await Promise.all([
            getAlert(),
            QRLogin(),
            getNightNurseID(),
            getNightNurseClientID(),
            getNightNurseSecret(),
            getServiceLevelTime(),
            getMbaTemplates(),
            getAlarmEmployees()
        ]);

        setOpenalarm_min(alertData[0].value);
        setLoginQR(loginData[0].value);
        setNightnurseid(nurseIdData[0].value);
        setNightnurseclientid(clientIdData[0].value);
        setNightnursesecret(secretData[0].value);
        setMbatemplates(mbaData[0].value);
        setServiceleveltime(servicelevelData[0].value);
        setAlarmEmployees(employees[0].value);
    };

    var alert_min = openalarm_min;

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className="loc_content">
                <h1>{t('settings')}</h1>

                <div className="alert alert-success alert-update" role="alert">
                    {t('settingsupdated')}
                </div>

                <div class="alert alert-danger" role="alert">
                    {t('somethingwrong')}
                </div>

                <div className="settingsTable" style={{ background: '#ffffff' }}>
                    <table id="DataTable" className="table responsive nowrap sortTable" width="100%">
                        <thead style={{ display: 'none' }}>
                            <tr class="rowtitle">
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{t('settingsqr')}</td>
                                <td>
                                    <label className='switch'>
                                        <input key={`${Math.floor((Math.random() * 1000))}-min`} id="loginQRBtn" defaultChecked={loginQR == 1 ? true : false} onChange={(event) => switchLoginQR(event)} className='loginQRBtn' type='checkbox' />
                                        <span className='slider round'></span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('settingsopenalarms')} ({t('minutes')})</td>
                                <td><input key={`${Math.floor((Math.random() * 1000))}-min`} className='form-control' defaultValue={alert_min} type='text' /> <i onClick={(event) => setAlertTime(event.target.parentNode.firstChild.value)} class="fas fa-save"></i></td>
                            </tr>
                            <tr>
                                <td>{t('settingsnightnurseid')}</td>
                                <td><input key={`${Math.floor((Math.random() * 1000))}-min`} className='form-control' defaultValue={nightnurseid} type='text' /> <i onClick={(event) => setNightNurseID(event.target.parentNode.firstChild.value)} class="fas fa-save"></i></td>
                            </tr>
                            <tr>
                                <td>{t('settingsnightnurseclient')}</td>
                                <td><input key={`${Math.floor((Math.random() * 1000))}-min`} className='form-control' defaultValue={nightnurseclientid} type='text' /> <i onClick={(event) => setNightNurseClientID(event.target.parentNode.firstChild.value)} class="fas fa-save"></i></td>
                            </tr>
                            <tr>
                                <td>{t('settingsnightnursesecret')}</td>
                                <td><input key={`${Math.floor((Math.random() * 1000))}-min`} className='form-control' defaultValue={nightnursesecret} type='text' /> <i onClick={(event) => setNightNurseSecret(event.target.parentNode.firstChild.value)} class="fas fa-save"></i></td>
                            </tr>
                            <tr>
                                <td>{t('servicelevel')} ({t('seconds')})</td>
                                <td><input key={`${Math.floor((Math.random() * 1000))}-min`} className='form-control' defaultValue={serviceleveltime} type='text' /> <i onClick={(event) => setServicelevelTime(event.target.parentNode.firstChild.value)} class="fas fa-save"></i></td>
                            </tr>
                            <tr>
                                <td>{t('Mbatemplates')}</td>
                                <td>
                                    <label className='switch'>
                                        <input key={`${Math.floor((Math.random() * 1000))}-min`} id="loginQRBtn" defaultChecked={mbatemplates == 1 ? 'True' : ''} onChange={(event) => setMbaTemplates(event)} className='loginQRBtn' type='checkbox' />
                                        <span className='slider round'></span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('Alarmemployees')}</td>
                                <td>
                                    <label className='switch'>
                                        <input key={`${Math.floor((Math.random() * 1000))}-min`} id="loginQRBtn" defaultChecked={alarmEmployees == 1 ? 'True' : ''} onChange={(event) => setEmployees(event)} className='loginQRBtn' type='checkbox' />
                                        <span className='slider round'></span>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withAuth(Settings);