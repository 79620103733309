import React, { useEffect, useState } from 'react';
import withRouter from '../components/withRouter';
import { useTranslation } from 'react-i18next';

const LocationSelector = (props) => {
    const { t } =useTranslation();
    const [locations, setLocations] = useState([]);
    const [showSelector, setShowSelector] = useState(false);

    const setCurrentLocation = (event) => {
        var location = event.target.value;
        var kepler = event.target.options[event.target.selectedIndex].getAttribute("data-kepler");

        if(localStorage.getItem('currentADLocation')){
            if(location !== localStorage.getItem('currentADLocation')){
                localStorage.setItem('Role', "0");
            }else{
                localStorage.setItem('Role', localStorage.getItem('CurrentRole'));
            }
        }

        if(location == 0){
            localStorage.removeItem('currentLocation');
        }else{
            localStorage.setItem('currentLocation', location);
        }

        kepler !== "0" ? localStorage.setItem('keplervalue', kepler) : localStorage.removeItem('keplervalue');
        window.location.reload();
        localStorage.removeItem('groups');
    }

    const findKepler = async(location) => {
        const rooms = await import("../data/rooms.js");
        const params = await rooms.getRoomConfig(location.locationid);
        const kepler = params.find((param) => param.name === "KeplerID");
        return kepler;
    }

    const getData = async () => {
        const rooms = await import("../data/rooms.js");
        const objects = await import("../data/objects.js");
        const data = await objects.getAllLocations(); 
        // const data = [{
        //     kepler: 'Blijendaal',
        //     locationid: '4',
        //     locationname: 'Oss - hoofdkantoor',
        //     routename: 'Default'
        // }] 
        
        console.log(data);
        
        data.forEach(async location => {
            const params = await rooms.getRoomConfig(location.locationid);
            const kepler = findKepler(location);
            location.kepler = kepler && kepler ? kepler.value : 0;

            if(!localStorage.getItem('currentLocation') && localStorage.getItem('AD_office') && localStorage.getItem('AD_office').toLowerCase() == location.locationname.toLowerCase()){             
                localStorage.setItem('currentLocation', location.locationid);
                localStorage.setItem('currentADLocation', location.locationid);
                if(!localStorage.getItem('dashboard-refreshed')){
                    localStorage.setItem('dashboard-refreshed', true);
                    window.location.reload();
                }
            }
        });
        
        if(data.length === 1 && window.location.href.split('/')[3] !== 'manage'){
            localStorage.setItem('currentLocation', data[0].locationid);
            if(!localStorage.getItem('dashboard-refreshed')){
                localStorage.setItem('dashboard-refreshed', true);
                window.location.reload();
            }
        }

        if((data.length === 1 && window.location.href.split('/')[3] === 'manage') || data.length > 1) {
            setShowSelector(true);
        }

        if(!localStorage.getItem('keplervalue') && data.length === 1){
            const currentkepler = await findKepler(data[0]);
            localStorage.setItem('keplervalue', currentkepler.value);
        }

        setLocations(data);
    }

    useEffect(() => {
        console.log(window.location.href.split('/'));
        getData();
    }, [])

    return (
        <React.Fragment>
        {showSelector && (
            <div className='select_location'>
                <div className="menu-item-label">
                    <div className="menu-item-label-name">{t('location_selector')}</div>
                </div>
                <select onChange={(event) => setCurrentLocation(event)} className="form-control" defaultValue={localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''} id="locations" name="locations">
                    {!localStorage.getItem('currentADLocation') && (<option key="0" data-kepler="0" value="0">{t('location_selector')}</option>)}
                    {locations ? locations.map((location, i) => {
                        return (
                            <option key={i + 1} data-kepler={location.kepler ? location.kepler : '0'} value={location.locationid}>{location.locationname}</option>
                        )
                    }) : ''}
                </select>
            </div>
        )}
        </React.Fragment>
    );
}

export default withRouter(LocationSelector);