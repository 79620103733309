import Config from "../config.json";

export const getAllLanguages = () => {
    return fetch(Config.API_URL + `/mba/languages/get`, {
      "method": "GET",
      "headers": {
        "Authorization": "Bearer " + localStorage.getItem('id_token'),
        "Content-Type": "application/x-www-form-urlencoded",
        "userid": localStorage.getItem('UserID'),
        "customerid": localStorage.getItem('CustomerID'),
      }
    })
      .then(response => response.json())
      .then(data => {
        return data;
      })
  }

  export const getActiveLanguages = () => {
    return fetch(Config.API_URL + `/mba/languages/get/active`, {
      "method": "GET",
      "headers": {
        "Authorization": "Bearer " + localStorage.getItem('id_token'),
        "Content-Type": "application/x-www-form-urlencoded",
        "userid": localStorage.getItem('UserID'),
        "customerid": localStorage.getItem('CustomerID'),
      }
    })
      .then(response => response.json())
      .then(data => {
        return data;
      })
  }

  export const getLanguage = (langid) => {
    return fetch(Config.API_URL + `/mba/language/${langid}`, {
      "method": "GET",
      "headers": {
        "Authorization": "Bearer " + localStorage.getItem('id_token'),
        "Content-Type": "application/x-www-form-urlencoded",
        "userid": localStorage.getItem('UserID'),
        "customerid": localStorage.getItem('CustomerID'),
        "langid": langid
      }
    })
      .then(response => response.json())
      .then(data => {
        return data;
      })
  }

  export const insertLanguage = (code, name, countrycode) => {
    return fetch(Config.API_URL + `/mba/languages/insert`, {
      "method": "GET",
      "headers": {
        "Authorization": "Bearer " + localStorage.getItem('id_token'),
        "Content-Type": "application/x-www-form-urlencoded",
        "userid": localStorage.getItem('UserID'),
        "customerid": localStorage.getItem('CustomerID'),
        "code": code,
        "name": name,
        "countrycode": countrycode
      }
    })
      .then(response => response.json())
      .then(data => {
        return data;
      })
  }

  export const updateLanguage = (langid, code, name, countrycode, active) => {
    return fetch(Config.API_URL + `/mba/language/${langid}/update`, {
      "method": "GET",
      "headers": {
        "Authorization": "Bearer " + localStorage.getItem('id_token'),
        "Content-Type": "application/x-www-form-urlencoded",
        "userid": localStorage.getItem('UserID'),
        "customerid": localStorage.getItem('CustomerID'),
        "langid": langid,
        "code": code,
        "name": name,
        "countrycode": countrycode,
        "active": active
      }
    })
      .then(response => response.json())
      .then(data => {
        return data;
      })
  }

  export const deleteLanguage = (langid, langname) => {
    return fetch(Config.API_URL + `/mba/language/${langid}/delete`, {
      "method": "GET",
      "headers": {
        "Authorization": "Bearer " + localStorage.getItem('id_token'),
        "Content-Type": "application/x-www-form-urlencoded",
        "userid": localStorage.getItem('UserID'),
        "customerid": localStorage.getItem('CustomerID'),
        "langid": langid,
        "langname": langname
      }
    })
      .then(response => response.json())
      .then(data => {
        return data;
      })
  }