import React, { Component, useState, useEffect } from 'react';
import './Phonebook.css';
import withAuth from '../../components/Login/withAuth';
import { initDataTable, bulkSelect, confirmDelete, crudAlertControl, filterDataTable, fillDataTableFilters } from "../../Functions.js";
import ModalDelete from "../../components/ModalDelete";
import LocationAlert from '../../components/LocationAlert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const PhonebookExternal = () => {
    const [contacts, setContacts] = useState([]);
    const [teams, setTeams] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDelModal, setDelShowModal] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const getData = async () => {
        const [book, teams] = await Promise.all([
            import("../../data/phonebook.js"),
            import("../../data/teams.js")
        ])

        const bookData = await book.getExtPhonebook();
        const teamData = await teams.getTeams();

        setContacts(bookData);
        setTeams(teamData);
        initDataTable();    
    }

    const addContact = async (event) => {
        event.preventDefault();
        const book = await import("../../data/phonebook.js");
        const data = await book.addExtContact(event.target.name.value, event.target.contactnumber.value, event.target.contactteam.value);

        crudAlertControl('add', data);
        getData();
        modalHide();
    }

    const deleteContact = async (row, objectID) => {
        const book = await import("../../data/phonebook.js");
        const data = await book.delExtContact(objectID);

        crudAlertControl('delete', data);
        await getData();
    }

    const bulkDeleteContacts = async (event, array) => {
        const book = await import("../../data/phonebook.js");

        await Promise.all(array.map(async (contact) => {
            const data = await book.delExtContact(contact.id);
            crudAlertControl('delete', data);
        }))

        await getData();
    }

    const modalShow = () => {
        setShowModal(true);
    }

    const modalHide = () => {
        setShowModal(false);
    }

    useEffect(()=> {
        getData();
    }, [])

    var bulk = [];

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <div onClick={() => modalShow()} className="btn btn-add"> <i class="fa-solid fa-plus"></i> {t('add')}</div>
                <h1>{t('phonebook')} - {t('phonebookextern')}</h1>

                <div className="alert alert-success alert-add" role="alert">
                    {t('newcontact')}
                </div>

                <div className="alert alert-success alert-delete" role="alert">
                    {t('delcontact')}
                </div>

                <div className="alert alert-danger" role="alert">
                    {t('somethingwrong')}
                </div>

                <div className="filters">
                    {t('team')}:
                    <select id="EmployeesTeams" onChange={(event) => filterDataTable("reporthistoryalarm-employeesteams", 3, event.target.value, true)}>
                        <option data-id="0" value="0">{t('select')}</option>
                    </select>

                    <div id="buttons" style={{ float:'right' }}></div>
                </div>

                <div className="DataTable_Container">
                    <div className="bulk" style={{ marginTop: '20px' }}>
                        <span style={{ color: '#000000', marginRight: '10px' }}>{t('bulkselect')}: </span>
                        <i onClick={(event) => confirmDelete(function () { bulkDeleteContacts(event, bulk) })} className="btn-delete fas fa-trash-alt"></i>
                    </div>
                    
                    <table id="DataTable" className="showHead table responsive nowrap">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{t('name')}</th>
                                <th>{t('phonenumber')}</th>
                                <th>{t('team')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {contacts.map((contact, i) => {
                                var name = contact.name;
                                var firstLetter = name.charAt(0);

                                fillDataTableFilters(document.getElementById('EmployeesTeams'), contact.team);

                                return (
                                    <tr>
                                        <td style={{ width: '1px' }} data-id={contact.id}><input type="checkbox" className="bulkSelect" onChange={(event) => bulkSelect(event, bulk)} /></td>
                                        <td onClick={() => navigate('/phonebook/contact/' + contact.id)}><div className="contact-icon">{firstLetter}</div> {contact.name}</td>
                                        <td>{contact.phonenumber}</td>
                                        <td><div className='contact-team'>{contact.team}</div></td>
                                        <td><i onClick={(event) => confirmDelete(t('delitem'), function () { deleteContact(event, contact.id) })} className="btn-delete fas fa-trash-alt"></i></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <ModalDelete />

                {showModal && (
                    <div style={{ display: 'block' }} id="add" className="modal fade in">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('addcontact')}</h4>
                                <div onClick={() => modalHide()} className="close">&times;</div>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={(event) => addContact(event)}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="name">{t('name')}</label>
                                                <input type="text" name="name" id="name" className="form-control" required />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="contactnumber">{t('phonenumber')}</label>
                                                <input type="text" name="contactnumber" id="contactnumber" className="form-control" required />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="contactteam">{t('team')}</label>
                                                <select name="contactteam" id="contactteam" className="form-control" required>
                                                <option value="">{t('select')}</option>
                                                    {teams.map((team, i) => {
                                                        return (<option value={team.teamid}>{team.name}</option>)
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6" onClick={() => modalHide()}>
                                            <button id="cancel" className="btn btn-red cancel">{t('cancel')}</button>
                                        </div>

                                        <div className="col-md-6">
                                            <button type="submit" className="btn btn-green">{t('add')}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> 
                )}
            </div>
        </React.Fragment>
    );
}

export default withAuth(PhonebookExternal);