import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import withAuth from '../../components/Login/withAuth';
import * as moment from 'moment';
import '../../components/DataTables.css';
import LocationAlert from '../../components/LocationAlert';
import { useTranslation } from 'react-i18next';
import VirtualRoundCard from '../../components/VirtualRoundCard';
moment.locale('nl');

const VirtualRound = (props) => {
    const { t } = useTranslation();
    const [rooms, setRooms] = useState([]);
    
    const getData = async () => {
        const [rooms, roomdevices] = await Promise.all([
            import("../../data/rooms"),
            import("../../data/devices")
        ])

        const roomsData = await rooms.getRoomsByLocation(localStorage.getItem('currentLocation'));
        const roomsWithKepler = new Set();

        const hasRoomKepler = async (roomid) => {
            if(roomsWithKepler.has(roomid)){
                return true;
            }else{
                const devices = await rooms.getDevicesofRoom(roomid);
                const keplerParams = await Promise.all(devices.map(async device => await roomdevices.getDeviceConfig(device.deviceid)));
                const hasKepler = keplerParams.some(params => params.some(param => param.parametername === "KeplerID"));

                if(hasKepler){
                    roomsWithKepler.add(roomid);
                    return true;
                }
            }

            return false;
        }

        const roomsWithKeplerData1 = await Promise.all(roomsData.map(async room => ({roomid: room.roomid, hasKepler: await hasRoomKepler(room.roomid)}))).then(results => results.filter(item => item.hasKepler));
        setRooms(roomsWithKeplerData1);
    }
    
    useEffect(() => {
        getData();
    }, [])
    
    return(
        <React.Fragment>
            <LocationAlert />
            <div className='loc_content'>
                <h1>{t('virtualround')}</h1>
                <div className='row' style={{ padding: '20px 25px' }}>
                    {rooms ? rooms.map(room => {
                        return(
                            <div className='col-md-4'>
                                <VirtualRoundCard roomid={room.roomid} />
                            </div>
                        )
                    }) : ''}
                </div>
            </div>
        </React.Fragment>
    )
}

export default withAuth(VirtualRound)