import React, { Component } from 'react';
import './Dashboard.css';
import withAuth from '../../components/Login/withAuth';
import { getCams } from "../../data/virtualtour.js";
import { initCams } from "../../Functions.js";
import ReactHlsPlayer from 'react-hls-player';

class VirtualTour extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cams: [],
      show: false
    }
  }

  convertCam(ipaddress, port, username, password) {
    if (ipaddress && port && username && password) {
      return fetch("http://localhost:8000/convert", {
        "method": "GET",
        "headers": {
          "input": "rtsp://" + username + ":" + password + "@" + ipaddress + ":" + port + "/Streaming/Channels/102?transportmode=multicast&profile=Profile_1"
        }
      })
        .then(response => response.json())
        .then(data => {
          return data.toString();
        })
    }
  }

  componentDidMount() {
    getCams().then(data => {
      this.setState({
        cams: data
      });
    });

    initCams();

    setTimeout(function () {
      var items = document.getElementsByClassName('carousel-item');
      if (items.length > 0) {
        items[0].classList.add("active");
      }
    }, 1000);

    setTimeout(() => this.setState({
      show: true
    }), 15000);
  }

  render() {

    return (
      <React.Fragment>
        <h2>VirtualTour</h2>

        <div id="carouselExampleControls" className="carousel slide" data-interval="false" data-ride="carousel">
          <div className="carousel-inner">

            {this.state.cams.map((cam, i) => {

              var camURL = '';
              var rtsp = false

              if (cam.rtsp === 1) {
                rtsp = true;

                var IP = "172.20.98.61";
                var output = "output" + IP.split(".").join("") + ".m3u8";

                this.convertCam("172.20.98.61", cam.port, cam.username, cam.password).then((data) => {
                  if (data && data !== '') {
                    camURL = data;
                  }
                });
              }

              setTimeout(function () {
                if (camURL && camURL !== '') {
                  camURL = camURL;
                }
              }, 11000)


              return (
                <div className="carousel-item" data-host={cam.value}>

                  {rtsp === true ?
                    <div>
                      {this.state.show ? <ReactHlsPlayer
                        src={`http://localhost:8000/streams/${output}`}
                        autoPlay={true}
                        controls={true}
                        width="100%"
                        height={480}
                        muted="muted"
                      /> : "Stream wordt geladen"}
                    </div>
                    : <img alt="cam" className="CamPic2" src={`http://${cam.value}/record/current.jpg?rand=1`} />}
                  <div className="carousel-caption d-none d-md-block">
                    <h5 style={{ background: '#333' }}>Camera {cam.room}</h5>
                  </div>
                </div>
              )
            })}

          </div>
          <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only" >Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default withAuth(VirtualTour);
