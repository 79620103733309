import React, { useEffect, useState } from 'react';
import withAuth from '../components/Login/withAuth';
import Menu from './components/Menu';
import { CutOnLength, checkMBAUserPermission, confirmDelete, confirmDuplicate, crudAlertControl, initDataTable } from './Functions';
import { fillDataTableFilters } from '../Functions';
import Filter from '../components/Filter';
import ModalDelete from './components/ModalDelete';
import { useNavigate } from 'react-router';

const ResponseRules = () => {
    const [rules, setRules] = useState([]);
    const navigate = useNavigate();

    const getData = async () => {
        const response = await import('./data/ResponseRules');
        const data = await response.getAllResponseRules();
        setRules(data);
        initDataTable();
    }

    const copyRule = async (ruleid) => {
        const response = await import('./data/ResponseRules');
        const data = await response.copyResponseRules(ruleid);
        await getData();
        crudAlertControl('duplicate', data);
    }

    const delRule = async (ruleid) => {
        const response = await import('./data/ResponseRules');
        const data = await response.deleteResponseRules(ruleid);
        await getData();
        crudAlertControl('delete', data);
    }

    useEffect(() => {
        checkMBAUserPermission();
        getData();
    }, [])

    return(
        <React.Fragment>
            <Menu />

            <div className="alert alert-success alert-dup" role="alert">
                Responserule gedupliceerd.
            </div>

            <div className="alert alert-success alert-add" role="alert">
                Responserule aangemaakt.
            </div>

            <div className="alert alert-success alert-delete" role="alert">
                Responserule verwijderd.
            </div>

            <div className="alert alert-danger" role="alert">
                Er is iets niet goed gegaan. Probeer het later nog eens.
            </div>
            
            <h2>Responserules</h2>
            
            <div onClick={() => { window.location.href = "/mba-admin/responserules/templates" }} className="btn btn-blue">Templates</div>
            <div onClick={() => { window.location.href = "/mba-admin/responserules/clusters" }} className="btn btn-blue">Clusters</div>
            <div onClick={() => navigate('/mba-admin/responserules/insert')} className="btn btn-add">Toevoegen</div>
            <div style={{float: 'right', marginTop: '-22px', marginRight: '30px', color: '#333'}}><Filter type="select" text="Cluster" id="category" column="6" item="mba-responserule-category" exact={false} /></div>

            <div className="DataTable_Container">
                <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                    <thead>
                        <tr className="rowtitle">
                            <th>Incoming</th>
                            <th>From</th>
                            <th>Message</th>
                            <th>Outgoing</th>
                            <th>To</th>
                            <th>Message</th>
                            <th>Cluster</th>
                            <th>Active</th>
                            <th>Enabled</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rules.map((rule, i) => {
                            fillDataTableFilters(document.getElementById('category'), rule.category);

                            let $_Incoming = rule.incomingdevice === null && rule.incomingobject !== "" ? "Object: " + rule.incomingobject : rule.incomingobject === null && rule.incomingdevice !== "" ? "Device: " + rule.incomingdevice : "";
                            let $_Outgoing = rule.outgoingobject !== "" || rule.outgoingobject !== null ? "Route: " + rule.outgoingobject : "";
                            var dotColorActive = rule.active == 1 ? 'green' : 'red';
                            var dotColorEnabled = rule.enabled == 1 ? 'green' : 'red';

                            return (
                            <tr key={i}>
                                <td onClick={() => navigate(`/mba-admin/responserules/${rule.id}`)}>{rule.incoming}</td>
                                <td onClick={() => navigate(`/mba-admin/responserules/${rule.id}`)}>{$_Incoming}</td>
                                <td onClick={() => navigate(`/mba-admin/responserules/${rule.id}`)}>{CutOnLength(rule.incomingfrom, 30)}</td>
                                <td onClick={() => navigate(`/mba-admin/responserules/${rule.id}`)}>{rule.outgoing}</td>
                                <td onClick={() => navigate(`/mba-admin/responserules/${rule.id}`)}>{$_Outgoing}</td>
                                <td onClick={() => navigate(`/mba-admin/responserules/${rule.id}`)}>{CutOnLength(rule.outgoingto, 30)}</td>
                                <td onClick={() => navigate(`/mba-admin/responserules/${rule.id}`)}>{rule.category}</td>
                                <td><div style={{ display: 'inline-block', width: '10px', height: '10px', background: dotColorActive, borderRadius: '100%' }}></div></td>
                                <td><div style={{ display: 'inline-block', width: '10px', height: '10px', background: dotColorEnabled, borderRadius: '100%' }}></div></td>
                                <td>
                                    <i class="fas fa-copy" onClick={() => confirmDuplicate(function () { copyRule(rule.id) }) } style={{ marginRight:'10px' }}></i>
                                    <i style={{ color: '#ff0000', marginTop: '3px', cursor: 'pointer' }} onClick={() => confirmDelete(function () { delRule(rule.id) })} class="fas fa-trash-alt"></i>
                                </td>
                            </tr>);
                        })}
                    </tbody>
                </table>
            </div>

            <ModalDelete />
        </React.Fragment>
    )
}

export default withAuth(ResponseRules);