import React, { Component, useEffect, useState } from 'react';
import './Manage.css';
import '../components/DataTables.css';
import withAuth from '../components/Login/withAuth';
import { initDataTable, bulkSelect, confirmDelete, crudAlertControl } from "../Functions.js";
import { useNavigate } from 'react-router';
import ModalDelete from "../components/ModalDelete";
import { useTranslation } from 'react-i18next';

const TeamRoutes = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [routes, setRoutes] = useState([]);
    const [locations, setLocations] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const bulk = [];

    const insertRoute = async (event) => {
        event.preventDefault();

        const routesData = await import("../data/routes");
        const data = await routesData.addRoute(event.target.name.value);

        if(data && data.length > 0){
          navigate('/manage/teamroute/' + data[0].routeid);
        }
    }

    const deleteRoute = async (row, objectID, routename) => {
        const routesData = await import("../data/routes");
        const data = await routesData.delRoute(objectID, routename);

        getData();
        crudAlertControl('delete', data);
    }

    const bulkDeleteRoute = async (event, array) => {
        const routesData = await import("../data/routes");
        array.forEach(async (route) => {
            const data = await routesData.delRoute(route);
            getData();
            crudAlertControl('delete', data);
        })
    }

    const getData = async (init) => {
        const [routesData, objectsData] = await Promise.all([
            import("../data/routes"),
            import("../data/objects")
        ])

        const getroutes = await routesData.getRoutes();
        const objects = await objectsData.getObjects();
        setRoutes(getroutes);
        setLocations(objects);
        initDataTable();
    }

    const modalShow = () => {
        setShowModal(true);
    }

    const modalHide = () => {
        setShowModal(false);
    }

    useEffect(() => {
        getData();
    }, [])

  return (
    <React.Fragment>
      <div className='loc_content'>
        <div onClick={() => modalShow()} className="btn btn-add">{t('add')}</div>
        <h1>{t('routes')}</h1>

        <div className="alert alert-success alert-add" role="alert">
          {t('newroute')}
        </div>

        <div className="alert alert-success alert-delete" role="alert">
          {t('delroute')}
        </div>

        <div class="alert alert-danger" role="alert">
          {t('somethingwrong')}
        </div>

        <div className="DataTable_Container">
          <div class="bulk" style={{ marginTop: '20px' }}>
            <span style={{ color: '#000000', marginRight: '10px' }}>{t('bulkselect')}: </span>
            <i onClick={(event) => confirmDelete(function () { bulkDeleteRoute(event, bulk) })} className="btn-delete fas fa-trash-alt"></i>
          </div>
          <table id="DataTable" class="table responsive nowrap" width="100%">
            <thead>
              <tr class="rowtitle">
                <th></th>
                <th>{t('name')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {routes.map((route, i) => {
                return (
                  <tr key={i}>
                    <td style={{ width: '1px' }} data-id={route.routeid}><input type="checkbox" className="bulkSelect" onChange={(event) => bulkSelect(event, this.bulk)} /></td>
                    <td onClick={(event) => { navigate(`/manage/teamroute/${route.routeid}`) }}>{route.routename}</td>
                    <td><i onClick={(event) => confirmDelete(t('delitem'), function () { deleteRoute(event, route.routeid, route.routename) })} class="btn-delete fas fa-trash-alt"></i></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      <ModalDelete />

        {showModal && ( 
            <div style={{display: 'block'}} id="add" class="modal fade in">
                <div class="modal-content ">
                  <div class="modal-header">
                    <h4 class="modal-title">{t('addroute')}</h4>
                    <div onClick={() => modalHide()} class="close">&times;</div>
                  </div>
                  <div class="modal-body">
                    <form onSubmit={(event) => insertRoute(event)}>
                      <div className="row">
                        <div className="col-md-12">
                          <div class="form-group">
                            <label for="teamname">{t('name')}</label>
                            <input type="text" name="name" id="name" class="form-control" required />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div onClick={() => modalHide()} id="cancel" class="btn btn-red cancel">{t('cancel')}</div>
                        </div>
                        <div class="col-md-6">
                          <button type="submit" className="btn btn-green">{t('add')}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div> 
            </div>
        )}
      
    </React.Fragment>
  );
}

export default withAuth(TeamRoutes);
