import React, { Component } from 'react';
import withAuth from '../Login/withAuth';
import './style.css';

const BI = () => {
    var customerid = localStorage.getItem('CustomerID');

    return (
        <div className="container" style={{ background: '#ffffff', boxShadow: '6px 16px 31px -18px #b7bcd1', padding: '0 40px' }}>
            <img alt="logo" style={{ marginTop: '50px', width: '10%' }} src="/images/hps-logo.png" />

            <h1 style={{ fontSize: '25px', marginTop: '50px', width: '100%', borderBottom: 'solid 1px #ddd', paddingBottom: '10px' }}>Power BI implementatie</h1>
            <div className="row">
                <div className="col-md-12">
                    <p style={{ fontSize: '14px' }}>
                        Met de HPS Connected Health Center API wordt het mogelijk gemaakt om de data die in de webinterface wordt weergegeven
                        ook in Microsft Power BI te implementeren. Op deze pagina leggen we u uit hoe u de data in Microsft Power BI zet met behulp van API calls.
                    </p>

                    <p style={{ fontSize: '14px' }}>
                        <strong>Power BI Web services</strong><br />
                        In het programma kunt u data ophalen die u kunt gebruiken voor de visuals. In het lint van Power BI, in de home tab, klikt u op 'Get data'.
                        In de dropdown selecteert u 'Web'. Nu verschijnt er een popup. In deze popup klik je op 'Advanced'. Bij URL parts vul je de API url in, bijvoorbeeld https://api.domein.nl/pad-naar-data.
                        Dit kan in een veld maar je kan er ook voor kiezen om het tweede veld te kiezen voor het 'pad-naar-data' gedeelte. In het veld eronder krijg je de complete url te zien. Vervolgens bij
                        'HTTP request header parameters' kun je de betreffende headers meegeven. Welke API URL's met parameters mogelijk zijn, worden op deze pagina weergegeven.
                    </p>

                    <p>* De waarde van customerid in de api calls hieronder is ook de daadwerkelijke customerid waarmee u bent ingelogd. Deze kunt u dan ook gebruiken.</p>

                    <h2 style={{ margin: '0' }}>API Calls</h2>

                    <div className="api-call">
                        <div className="description"><span className="call-method get">GET</span> Lijst van alle alarmen met teams, alarmtypes, alarmdatums en op welke kamer/appartement het alarm af gaat</div>
                        <pre className="callurl">/BI/alarms</pre>
                        <div className="headers">
                            <p><strong>Headers:</strong><br />
                                customerid: {customerid}</p>
                        </div>
                    </div>

                    <div className="api-call">
                        <div className="description"><span className="call-method get">GET</span> Lijst van alle medewerkers met inlog- en uitlogtijden, locatie en op welk apparaat ze zijn/waren ingelogd.</div>
                        <pre className="callurl">/BI/employees</pre>
                        <div className="headers">
                            <strong>Headers:</strong><br />
                            customerid: {customerid}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withAuth(BI);