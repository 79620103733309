import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { getActiveLanguages } from './data/languages';

const activeLangs = new Promise((resolve, reject) => {
    resolve(getActiveLanguages());
});

if(localStorage.getItem('id_token')){
    await Promise.all([activeLangs]).then((values) => {
        var langlist = []
    
        values[0].forEach(lang => {
            langlist.push(lang.code);
        })
    
        i18n
        .use(initReactI18next)
        .use(LanguageDetector)
        .use(HttpApi)
        .init({
            supportedLngs: langlist,
            fallbackLng: "en",
            detection: {
            order: ['localStorage', 'cookie', 'htmlTag'],
            caches: ['cookie']
            },
            backend: {
            loadPath: '/languages/{{lng}}.json',
            }
        });
    })
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

reportWebVitals();
