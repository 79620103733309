import Config from "../config.json";

export const getAlarm = (alarmid) => {
  return fetch(Config.API_URL + "/mba/alarm/" + alarmid, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "alarmid": alarmid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getNotification = (alarmid) => {
  return fetch(Config.API_URL + "/mba/notification/" + alarmid, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "alarmid": alarmid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getCallOfAlarm = (alarmid) => {
  return fetch(Config.API_URL + `/alarm/get/${alarmid}/call`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "alarmid": alarmid,
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID')
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const activateCallOfAlarm = (alarmid) => {
  return fetch(Config.API_URL + `/alarm/activatecall/${alarmid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "objectid": alarmid,
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID')
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const deactivateCallOfAlarm = (alarmid) => {
  return fetch(Config.API_URL + `/alarm/deactivatecall/${alarmid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "objectid": alarmid,
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID')
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const acceptAlarm = (alarmid, objectalarmteamid) => {
  return fetch(Config.API_URL + `/alarm/addacceptation/${alarmid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "id": alarmid,
      "objectid": localStorage.getItem('UserID'),
      "objectalarmteamid": objectalarmteamid
    }
  })
    .then(response => response.json())
    .then(data => {
      window.location.reload();
    })
}

export const finishAlarm = (alarmid, objectalarmteamid, deviceid) => {
  return fetch(Config.API_URL + `/alarm/addconfirmation/${alarmid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "id": alarmid,
      "objectid": localStorage.getItem('UserID'),
      "objectalarmteamid": objectalarmteamid,
      "deviceid": deviceid
    }
  })
    .then(response => response.json())
    .then(data => {
      window.location.reload();
    })
}

export const cancelAlarm = (alarmid, objectalarmteamid) => {
  return fetch(Config.API_URL + `/alarm/adddecline/${alarmid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "id": alarmid,
      "objectid": localStorage.getItem('UserID'),
      "objectalarmteamid": objectalarmteamid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getAlarms = () => {
  return fetch(Config.API_URL + "/alarm/get", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('GUID'),
      "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getAlarmTypes = () => {
  return fetch(Config.API_URL + "/mba/alarmtypes", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getAlarmHistory = () => {
  return fetch(Config.API_URL + "/mba/alarm/history/get", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getAlarmPerformanceTotals = (reportdatefrom, reportdatetill, serviceleveltime) => {
  return fetch(Config.API_URL + "/mba/alarmperformance/totals", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "reportdatefrom": reportdatefrom,
      "reportdatetill": reportdatetill,
      "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : '',
      "serviceleveltime": serviceleveltime
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getAlarmPerformance = (reportdatefrom, reportdatetill, serviceleveltime) => {
  return fetch(Config.API_URL + `/mba/alarmperformance`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "reportdatefrom": reportdatefrom,
      "reportdatetill": reportdatetill,
      "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : '',
      "serviceleveltime": serviceleveltime
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const enableAlarm = (alarmid, alarmname, objectid, objectname) => {
  return fetch(Config.API_URL + `/mba/alarmenable/${alarmid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('GUID'),
      "alarmid": alarmid,
      "alarmname": alarmname,
      "objectid": objectid ? objectid : '',
      "objectname": objectname ? objectname : ''
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const disableAlarm = (alarmid, alarmname, objectid, objectname) => {
  return fetch(Config.API_URL + `/mba/alarmdisable/${alarmid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('GUID'),
      "alarmid": alarmid,
      "alarmname": alarmname,
      "objectid": objectid ? objectid : '',
      "objectname": objectname ? objectname : ''
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getTechAlarms = (alarmid, reportdate) => {
  var url = '';
  alarmid ? url = Config.API_URL + `/mba/report/historyalarmtech/${alarmid}` : url = Config.API_URL + "/mba/report/historyalarmtech";

  return fetch(url, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "reportdate": reportdate ? reportdate : '',
      "alarmid": alarmid ? alarmid : ''
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const TechAlarmTeams = (alarmid) => {
  return fetch(Config.API_URL + `/mba/report/historyalarmtech/${alarmid}/teams`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "alarmid": alarmid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getYearAlarms = () => {
  return fetch(Config.API_URL + `/mba/stats/year`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "locationid": localStorage.getItem('currentLocation')
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getLogging = (date) => {
  return fetch(Config.API_URL + `/mba/report/alarmlogging`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "reportdate": date
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getAlarmNotes = (alarmid) => {
  return fetch(Config.API_URL + `/mba/notes/alarm`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "alarmid": alarmid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}