import Config from "../config.json";

export const getRooms = () => {
  return fetch(Config.API_URL + "/mba/room/get", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "locationid": localStorage.getItem('currentLocation'),
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getRoomsByLocation = (locationid) => {
  return fetch(Config.API_URL + "/mba/rooms/location", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "locationid": localStorage.getItem('currentLocation')
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getRoom = (roomid) => {
  return fetch(Config.API_URL + "/mba/room/get/" + roomid, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getRoomConfig = (roomid) => {
  return fetch(Config.API_URL + "/mba/organization/detailconfig/" + roomid, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "objectid": roomid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getAmRooms = () => {
  return fetch(Config.API_URL + "/mba/reports/activity/get", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "locationid": localStorage.getItem('currentLocation')
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

 export const getAmRoomAlarms = (roomid, reportdate) => {
  return fetch(Config.API_URL + "/mba/reports/activity/" + roomid + "/alarms", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "roomid": roomid,
      "reportdate": reportdate,
      "locationid": localStorage.getItem('currentLocation')
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getDevicesofRoom = (roomid) => {
  return fetch(Config.API_URL + `/mba/room/device/get/` + roomid, {
    "method": "GET",
    "headers": {
        "Authorization": "Bearer " + localStorage.getItem('id_token'),
        "Content-Type": "application/json",
        "userid": localStorage.getItem('UserID'),
        "customerid": localStorage.getItem('CustomerID'),
    }
})
    .then(response => response.json())
    .then(data => {
      return data;
    })
}