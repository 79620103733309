import React, { useState } from 'react';
import withAuth from '../components/Login/withAuth';
import Papa from "papaparse";
import './Manage.css';
import { addObject } from '../data/organisation';
import { addObjectParam } from '../data/organisation';
import { useTranslation } from 'react-i18next';

const Upload = () => {
  const [file, setFile] = useState(null);
  const { t } = useTranslation();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          results.data.forEach(item =>{
            addObject(event.target.type.value, 0, item.name, 1);
          
              if (event.target.type.value === 4) {
                addObjectParam(event.target.type.value, '', 11, '');
                addObjectParam(event.target.type.value, '', 12, '');
                addObjectParam(event.target.type.value, '', 13, '');
            }
          })
        },
      });
    };

    return (
      <React.Fragment>
          <h2>{t('upload')}</h2>
          <div className="orgDetails upload">
              <p>
                  {t('uploadtext')}
              </p>

              <form onSubmit={(event) => handleSubmit(event)}>
                  <div class="form-group">
                      <label for="teamrelated">{t('type')}:</label>
                      <select name="type" className='type'>
                          <option value="1">{t('location')}</option>
                          <option value="2">{t('building')}</option>
                          <option value="3">{t('floor')}</option>
                          <option value="4">{t('room')}</option>
                          <option value="5">{t('employee')}</option>
                          <option value="6">{t('client')}</option>
                      </select>
                  </div>

                  <div class="form-group">
                      <label for="uploadBtn">{t('file')}:</label>
                      <input className="uploadBtn" type="file" onChange={handleFileChange} />
                  </div>

                  <input type="submit" value={t('upload')} />
              </form>
          </div>

      </React.Fragment>
  )
}

export default withAuth(Upload);