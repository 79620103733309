import React, { Component, useEffect, useState } from 'react';
import './Manage.css';
import '../components/DataTables.css';
import withAuth from '../components/Login/withAuth';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const Organisation = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [organisations, setOrganisations] = useState([]);

    const getData = async () => {
        const [organisation, functions] = await Promise.all([
            import("../data/organisation.js"),
            import("../Functions")
        ])

        const data = await organisation.getOrganisations();
        setOrganisations(data);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
            <div className="btn btn-add" onClick={() => {navigate(`/manage/organisation/upload`)}}>{t('upload')}</div>
                <h1>Organisaties</h1>
                
                <div className="DataTable_Container">
                    <table id="DataTable" class="table responsive nowrap" width="100%">
                        <thead>
                            <tr class="rowtitle">
                                <th>{t('id')}</th>
                                <th>{t('name')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {organisations.map((organisation, i) => {
                                return (
                                    <tr onClick={() => { navigate(`/manage/organisation/object/${organisation.objecttypeid}`) }}>
                                        <td>{organisation.objecttypeid}</td>
                                        <td>{t(`${organisation.objecttypeslug}`)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withAuth(Organisation);
