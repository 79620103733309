import React, { useEffect } from 'react';
import './Header.css';
import '../mba/mba.css';
import withAuth from './Login/withAuth';
import TeamsSidebar from './Header/TeamsSidebar';
import AlarmsSidebar from './Header/AlarmsSidebar';
import LoggedInUser from './Header/LoggedInUser';
import AlarmAlert from './Header/AlarmAlert';
import { NavLink, useNavigate } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <React.Fragment>
            { localStorage.getItem('currentLocation') && localStorage.getItem('currentLocation') !== '' && localStorage.getItem('currentLocation') != 0 ? <AlarmAlert /> : '' }
            <div className="header">
                <img alt="logo" className='logo' src="/images/sltn-hps-logo-wit.png" />
                <div className="version">{t('version')} 3.5</div>

                <div className='header_right' style={{ float: 'right' }}>
                    <LoggedInUser user={props.user} />
                    { localStorage.getItem('Role') === "5" ? <NavLink className="mba-btn" to="/mba-admin/status" exact={true}>MBA</NavLink> : ''}
                    <LanguageSelector />
                    { localStorage.getItem('Role') === "2" || localStorage.getItem('Role') === "5" ? <span className="header-icon"><i onClick={() => { window.location.href = '/manage/notifications' }} style={{ color: '#ffffff' }} className='fa fa-cog'></i></span> : ''}
                    <AlarmsSidebar />
                    <TeamsSidebar />
                </div>
            </div>
        </React.Fragment>
    );
}

export default withAuth(Header);