import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
const $ = require('jquery');

const ModalDelete = (props) => {
    const { t } = useTranslation();

	return(
        <React.Fragment>
                <div className="modal" id='modal_dialog'>
                    <div className="modal-content" style={{ textAlign: 'center' }}>
                        <div className="modal-header">
                            <h4 className="modal-title">{t('delete')}</h4>
                            <div className="delclose" style={{ cursor: 'pointer' }}>&times;</div>
                        </div>
                        
                        <div className="modal-body">
                            <i class="fas fa-exclamation-circle"></i>
                            <div class='modalTitle'></div>

                            <div className="row">
                                <div className="col-md-6">
                                    <button id="btnNo" className="btn btn-red">{t('cancel')}</button>
                                </div>
                                <div className="col-md-6">
                                    <button id='btnYes' className="btn btn-green">{t('delete')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
	)
}

export default ModalDelete;