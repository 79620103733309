import React, { useState, useEffect } from 'react';
import withRouter from '../components/withRouter';
import { crudAlertControl } from '../Functions';
import { useTranslation } from 'react-i18next';
const $ = require('jquery');

const NightNurseDetail = (props) => {
    const { t } = useTranslation();
    const [roominfo, setRoominfo] = useState([]);
    const [devices, setDevices] = useState([]);
    const [streams, setStreams] = useState([]);

    const changeConfig = async (event, streamid, streamname) => {
        event.preventDefault();

        const configData = JSON.stringify(Object.fromEntries(
            Object.entries(event.target).map(([key, value]) => [event.target[key].id, value.value])
        ));

        const nightnurse = await import("../data/nightnurse");
        const data = await nightnurse.updateKeplerConfig(streamid, streamname, configData);

        $('.panel').css('display', 'none');
        $('.accordion').removeClass('active');
        crudAlertControl('update', data);
    }

    const toggleAccordionItem = (event) => {
        event.target.classList.toggle("active");
        var panel = event.target.nextElementSibling;

        panel.style.display = panel.style.display === "block" ? "none" : "block";
    }

    const getData = async () => {
        const [nightnurse, nightrooms, nightdevices, functions] = await Promise.all([
            import("../data/nightnurse"),
            import("../data/rooms"),
            import("../data/devices"),
            import("../Functions")
        ]);

        functions.nightnurse_token_check(["2", "5"]);

        const [roomData, configData, devicesData] = await Promise.all([
          nightrooms.getRoom(props.router.params.id),
          nightrooms.getRoomConfig(localStorage.getItem("currentLocation")),
          nightrooms.getDevicesofRoom(props.router.params.id)
        ]); 
        
        setRoominfo(roomData[0]);

        configData.forEach(async (item) => {
          if (item.name === "KeplerID") {
            const keplerstreams = await nightnurse.getKeplerStreams(localStorage.getItem('keplervalue'));
            setStreams(keplerstreams.flat());
          }
        });

        devicesData.forEach(async (device) => {
          const params = await nightdevices.getDeviceConfig(device.deviceid);
          const keplerParam = params.find((param) => param.parametername === "KeplerID");

          if (keplerParam) {
            device.params = keplerParam;
          }
        });

        setDevices(devicesData);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <h1>{roominfo.roomname}</h1>

                <div className="alert alert-success alert-update" role="alert">
                    {t('nightnursesaved')}
                </div>

                <div className="alert alert-danger" role="alert">
                    {t('somethingwrong')}
                </div>

                <div className='orgDetails'>
                    {devices.map((device, i) => {
                        if (device.params && device.params.parametervalue) {
                            const matchingStream = streams.find(stream => stream.name === device.params.parametervalue);

                            return (
                                <React.Fragment>
                                    <button className="accordion" onClick={(event) => toggleAccordionItem(event)}>{device.devicename} {device.params ? "(" + device.params.parametervalue + ")" : ''}</button>
                                    <div className="panel">
                                        {matchingStream && (
                                            <form method="POST" onSubmit={(event) => changeConfig(event, matchingStream.uuid, device.params.parametervalue)}>
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td>{t('seconds_statusnormal')}</td>
                                                        <td><input style={{ display: 'inline-block', width:'90%', marginRight: '10px', verticalAlign:'middle' }} type="range" max="600" min="10" className='form-range' id="allClearDurationThreshold" name="allClearDurationThreshold" defaultValue={matchingStream.notificationConfiguration.allClearDurationThreshold} onInput={(event) => {event.target.nextElementSibling.value = event.target.value}} /><output style={{ verticalAlign:'middle', display: 'inline-block' }}>{matchingStream.notificationConfiguration.allClearDurationThreshold}</output></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('seconds_cameraproblem')}</td>
                                                        <td><input style={{ display: 'inline-block', width:'90%', marginRight: '10px', verticalAlign:'middle' }} type="range" max="600" min="10" className='form-range' id="cameraProblemDurationThreshold" name="cameraProblemDurationThreshold" defaultValue={matchingStream.notificationConfiguration.cameraProblemDurationThreshold} onInput={(event) => {event.target.nextElementSibling.value = event.target.value}} /><output style={{ verticalAlign:'middle', display: 'inline-block' }}>{matchingStream.notificationConfiguration.cameraProblemDurationThreshold}</output></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('seconds_statusbath')}</td>
                                                        <td><input style={{ display: 'inline-block', width:'90%', marginRight: '10px', verticalAlign:'middle' }} type="range" max="600" min="10" className='form-range' id="inBathroomDurationThreshold" name="inBathroomDurationThreshold" defaultValue={matchingStream.notificationConfiguration.inBathroomDurationThreshold} onInput={(event) => {event.target.nextElementSibling.value = event.target.value}} /><output style={{ verticalAlign:'middle', display: 'inline-block' }}>{matchingStream.notificationConfiguration.inBathroomDurationThreshold}</output></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('seconds_falldetection')}</td>
                                                        <td><input style={{ display: 'inline-block', width:'90%', marginRight: '10px', verticalAlign:'middle' }} type="range" max="600" min="10" className='form-range' id="manDownDurationThreshold" name="manDownDurationThreshold" defaultValue={matchingStream.notificationConfiguration.manDownDurationThreshold} onInput={(event) => {event.target.nextElementSibling.value = event.target.value}} /><output style={{ verticalAlign:'middle', display: 'inline-block' }}>{matchingStream.notificationConfiguration.manDownDurationThreshold}</output></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('seconds_outofbed')}</td>
                                                        <td><input style={{ display: 'inline-block', width:'90%', marginRight: '10px', verticalAlign:'middle' }} type="range" max="600" min="10" className='form-range' id="outOfBedDurationThreshold" name="outOfBedDurationThreshold" defaultValue={matchingStream.notificationConfiguration.outOfBedDurationThreshold} onInput={(event) => {event.target.nextElementSibling.value = event.target.value}} /><output style={{ verticalAlign:'middle', display: 'inline-block' }}>{matchingStream.notificationConfiguration.outOfBedDurationThreshold}</output></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('seconds_outofroom')}</td>
                                                        <td><input style={{ display: 'inline-block', width:'90%', marginRight: '10px', verticalAlign:'middle' }} type="range" max="600" min="10" className='form-range' id="outOfRoomDurationThreshold" name="outOfRoomDurationThreshold" defaultValue={matchingStream.notificationConfiguration.outOfRoomDurationThreshold} onInput={(event) => {event.target.nextElementSibling.value = event.target.value}} /><output style={{ verticalAlign:'middle', display: 'inline-block' }}>{matchingStream.notificationConfiguration.outOfRoomDurationThreshold}</output></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('seconds_sitinbed')}</td>
                                                        <td><input style={{ display: 'inline-block', width:'90%', marginRight: '10px', verticalAlign:'middle' }} type="range" max="600" min="10" className='form-range' id="personGettingUpInBedDurationThreshold" name="personGettingUpInBedDurationThreshold" defaultValue={matchingStream.notificationConfiguration.personGettingUpInBedDurationThreshold} onInput={(event) => {event.target.nextElementSibling.value = event.target.value}} /><output style={{ verticalAlign:'middle', display: 'inline-block' }}>{matchingStream.notificationConfiguration.personGettingUpInBedDurationThreshold}</output></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('seconds_person')}</td>
                                                        <td><input style={{ display: 'inline-block', width:'90%', marginRight: '10px', verticalAlign:'middle' }} type="range" max="600" min="10" className='form-range' id="personVisibleDurationThreshold" name="personVisibleDurationThreshold" defaultValue={matchingStream.notificationConfiguration.personVisibleDurationThreshold} onInput={(event) => {event.target.nextElementSibling.value = event.target.value}} /><output style={{ verticalAlign:'middle', display: 'inline-block' }}>{matchingStream.notificationConfiguration.personVisibleDurationThreshold}</output></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('seconds_seob')}</td>
                                                        <td><input style={{ display: 'inline-block', width:'90%', marginRight: '10px', verticalAlign:'middle' }} type="range" max="600" min="10" className='form-range' id="soebDurationThreshold" name="soebDurationThreshold" defaultValue={matchingStream.notificationConfiguration.soebDurationThreshold} onInput={(event) => {event.target.nextElementSibling.value = event.target.value}} /><output style={{ verticalAlign:'middle', display: 'inline-block' }}>{matchingStream.notificationConfiguration.soebDurationThreshold}</output></td>
                                                    </tr>
                                                    <tr>
                                                        <td><input className="btn btn-save" style={{ float: 'none', marginTop: '0px', color: '#fff' }} type="submit" id="submit" name="submit" value={t('save')} /></td>
                                                        <td></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                        )}                                    
                                    </div>
                                </React.Fragment>
                            )
                        }
                    })}
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(NightNurseDetail);