import React, { Component, useEffect } from 'react';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const AlarmAlert = () => {
    const { t } = useTranslation();

    const getData = async () => {
        const [alarms, settings] = await Promise.all([
            import("../../data/alarms"),
            import("../../data/settings")
        ])

        const alert = await settings.getAlert();
        const data = await alarms.getAlarms();

        data.forEach(async (alarm) => {
            var now = moment();
            var end = alarm.down ? moment(alarm.down) : null;

            if (end != null) {
                var duration = moment.duration(now.diff(end));
                var minutes = duration.asMinutes();

                if (minutes > alert[0].value) {
                    document.querySelector(".openalarms_alert").style.display = 'block';
                }
            }
        })
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className="openalarms_alert"><i className="fas fa-bell"></i> {t('pending_alarms')}</div>
        </React.Fragment>
    );
}

export default withRouter(AlarmAlert);
