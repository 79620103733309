import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import withAuth from '../../components/Login/withAuth';
import * as moment from 'moment';
import '../../components/DataTables.css';
import { fillDataTableFilters } from "../../Functions.js";
import Filter from '../../components/Filter';
import TotalOpenAlarms from '../../components/TotalOpenAlarms';
import LocationAlert from '../../components/LocationAlert';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Export from '../../components/Export.js';
moment.locale('nl');
const $ = require('jquery');

const OpenAlarms = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const [alarms, setAlarms] = useState([]);

    const getData = async () => {
        const [alarmsData, functions] = await Promise.all([
            import("../../data/alarms.js"),
            import("../../Functions.js")
        ])
      
        if (localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '') {
            const data = await alarmsData.getAlarms();
            console.log(data);
            setAlarms(data);
            functions.initDataTable();
        }
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
            
                <h1>{t('openalarms')}</h1>

                <div className="filters">
                    <Filter type="select" text={t('typealarm')} id="typeAlarm" column="0" item="reporthistoryalarm-alarmtype" exact={false} />
                    <Filter type="select" text={t('typeroom')} id="roomAlarm" column="2" item="reporthistoryalarm-room" exact={false} />
                    <Export />
                </div>

                <div className="DataTable_Container">
                    <table id="DataTable" className="showHead openAlarms table responsive nowrap">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('type')}</th>
                                <th>{t('object')}</th>
                                <th>{t('createdon')}</th>
                                <th>{t('confirmedon')}</th>
                                <th>{t('status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {alarms.map((alarm, i) => {
                                console.log(alarm);
                                var notification = alarm.id.includes("N");

                                fillDataTableFilters(document.getElementById('typeAlarm'), alarm.type);
                                fillDataTableFilters(document.getElementById('roomAlarm'), alarm.object);

                                var StatusColor = alarm.down && alarm.down !== '' ? '#FFA500' : (alarm.finish !== '' ? 'rgb(92, 184, 92)' : '#FF0000');
                                var Statustext = alarm.down && alarm.down !== '' ? t('pending') : (alarm.finish !== '' ? t('handled') : t('nothandled'));

                                return (
                                    <tr key={i} onClick={() => { navigate(`/alarm/${alarm.id}`) }} className="openAlarm">
                                        <td><div className="pictogram"> <img alt="alarm icon" src={`/images/icons/${alarm.icon}.png`} height="30" width="30" /></div> {t(`${alarm.slug}`)} {notification ? "(" + alarm.trigger + ")" : ''}</td>
                                        <td>{alarm.object}</td>
                                        <td>{moment(alarm.date).format('DD-MM-YYYY HH:mm:ss')}</td>
                                        <td>{moment(alarm.down).format('DD-MM-YYYY HH:mm:ss')}</td>
                                        <td><div className='alarm-status' style={{ background: StatusColor }}></div> {Statustext}</td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withAuth(OpenAlarms);