
import React, { useEffect, useState } from 'react';
import withAuth from '../components/Login/withAuth.js';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const Notifications = () => {
    const [log, setLog] = useState([])
    const { t } = useTranslation();

    const getData = async () => {
        const [reports, alarmsData, functions] = await Promise.all([
            import("../data/reports.js"),
            import("../data/alarms.js"),
            import("../Functions.js")
        ])

        const alarms = await alarmsData.getAlarms();
        const neck = await reports.getNeckStatus();
        const sd = await reports.getSDStatus();

        const alarmsOrange = alarms.filter(alarm => alarm.finish === '' && alarm.down !== '').length;
        const alarmsRed = alarms.filter(alarm => alarm.finish === '' && alarm.down === '').length;
        const countAlarms = alarms.length;

        var notificationAlarms = t('nrofalarmspending');
        notificationAlarms = notificationAlarms.replace("{nrofalarms}", alarmsRed);

        const notificationlog = [{
            type: "Alarms",
            device: "",
            description: notificationAlarms
        }];

        neck.forEach(status => {
            const notification = { type: "Devices", device: status.objectname };
            notification.description = status.percentage < 10 ? t('lowbattery') : (status.percentage === 0 && t('lowbatteryshutdown') );

            const now = moment();
            const lastheartbeat = moment(status.lastheartbeat);
            const diffDuration = moment.duration(now.diff(lastheartbeat));
            let lastactive = '';

            if (diffDuration.days() > 0) {
                lastactive += diffDuration.days() + (diffDuration.days() === 1 ? ' ' + t('day') + ' ' : ' ' + t('days') + ' ');
            }

            if (diffDuration.hours() > 0) {
                lastactive += diffDuration.hours() + (diffDuration.hours() === 1 ? ' ' + t('hour') + ' ' : ' ' + t('hours') + ' ');
            }

            if (diffDuration.minutes() > 0) {
                lastactive += diffDuration.minutes() + (diffDuration.minutes() === 1 ? ' ' + t('minute') + ' ' : ' ' + t('minutes') + ' ');
            }

            if (diffDuration.days() > 0 || diffDuration.hours() > 0 || (diffDuration.days() === 0 && diffDuration.hours() === 0 && diffDuration.minutes() > 10)) {
                notification.description = t('lastactive') + ": " + lastactive;
            }

            notificationlog.push(notification);
        });

        sd.forEach(function (SD) {
            if (SD.batlevel < 10) {
                notificationlog.push({
                    type: "Devices",
                    device: SD.devicetype + ' ' + SD.devicename,
                    description: t('lowbattery')
                })
            } else if (SD.batlevel === 0) {
                notificationlog.push({
                    type: "Devices",
                    device: SD.devicetype + ' ' + SD.devicename,
                    description: t('lowbatteryshutdown')
                })
            }

            if (SD.pluggedin === 0) {
                notificationlog.push({
                    type: "Devices",
                    device: SD.devicetype + ' ' + SD.devicename,
                    description: t('notconnected')
                })
            }

            const now = moment();
            const lastheartbeat = moment(SD.lastheartbeat);

            const diff = now.diff(lastheartbeat);
            const diffDuration = moment.duration(diff);
            var lastactive = '';

            if (diffDuration.days() > 0) {
                lastactive += diffDuration.days() + (diffDuration.days() === 1 ? ' ' + t('day') + ' ' : ' ' + t('days') + ' ');
            }

            if (diffDuration.hours() > 0) {
                lastactive += diffDuration.hours() + (diffDuration.hours() === 1 ? ' ' + t('hour') + ' ' : ' ' + t('hours') + ' ');
            }

            if (diffDuration.minutes() > 0) {
                lastactive += diffDuration.minutes() + (diffDuration.minutes() === 1 ? ' ' + t('minute') + ' ' : ' ' + t('minutes') + ' ');
            }

            if (diffDuration.days() > 0 || diffDuration.hours() > 0 || (diffDuration.days() === 0 && diffDuration.hours() === 0 && diffDuration.minutes() > 10)) {
                notificationlog.push({
                    type: "Devices",
                    device: `${SD.devicename}`,
                    description: t('lastactive') + ": " + lastactive
                });
            }
        })

        setLog(notificationlog);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <h1>{t('systemalerts')}</h1>

                <div className="DataTable_Container">
                    <table id="DataTable" className="systemnotifications showHead table responsive nowrap">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('type')}</th>
                                <th>{t('device')}</th>
                                <th>{t('comment')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {log.map((notification) => {
                            return (
                                <tr>
                                    <td>{notification.type}</td>
                                    <td>{notification.device}</td>
                                    <td>{notification.description}</td>
                                </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withAuth(Notifications);