import React, { Component, useEffect, useState } from 'react';
import './Reports.css';
import '../../components/DataTables.css';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import { filterDataTable, fillDataTableFilters } from "../../Functions.js";
import LocationAlert from "../../components/LocationAlert.js";
import { useTranslation } from 'react-i18next';
import Export from '../../components/Export.js';
moment.locale('nl');

const Roomunits = () => {
    const [statusses, setStatusses] = useState([]);
    const { t } = useTranslation();

    const getData = async () => {
        const [reports, functions] = await Promise.all([
            import("../../data/reports.js"),
            import("../../Functions.js")
        ])

        const data = await reports.getSDStatus();
        setStatusses(data);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <h1>{t('roomunits')}</h1>
                <div class="filters">
                    {t('type')}:
                    <select id="type" onChange={(event) => filterDataTable("roomunit-type", 2, event.target.value, false)}>
                        <option data-id="0" value="">{t('select')}</option>
                    </select>

                    {t('location')}:
                    <select id="location" onChange={(event) => filterDataTable("roomunit-location", 1, event.target.value, false)}>
                        <option data-id="0" value="">{t('select')}</option>
                    </select>

                    <Export />
                </div>

                <div className="DataTable_Container">
                    <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('device')}</th>
                                <th>{t('location')}</th>
                                <th>{t('type')}</th>
                                <th>{t('lastsignal')}</th>
                                <th>{t('connected')}</th>
                                <th>{t('batlevel')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statusses.map((status, i) => {
                                fillDataTableFilters(document.getElementById('type'), status.devicetype);
                                fillDataTableFilters(document.getElementById('location'), status.object);

                                var plugged = status.pluggedin === 1 ? <i class="check fas fa-check"></i> : <i class="no-check fas fa-times"></i>;

                                return (
                                    <tr>
                                        <td>{status.devicename}</td>
                                        <td>{status.object}</td>
                                        <td>{status.devicetype}</td>
                                        <td>{moment(status.lastheartbeat).format('DD-MM-YYYY HH:mm:ss')}</td>
                                        <td>{plugged}<span style={{ marginLeft: '10px' }}>{status.pluggedin === 1 ? 'Aangesloten' : 'Niet aangesloten'}</span></td>
                                        <td>{status.batlevel}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(Roomunits);