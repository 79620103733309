import React, { useEffect, useState } from 'react';
const $ = require('jquery');

const ModalDelete = () => {
	return(
		<div className="modal" id='modal_dialog'>
            <div className="modal-content" style={{ textAlign: 'center' }}>
                <div className="modal-header">
                    <h4 className="modal-title">Verwijderen</h4>
                    <div className="close">&times;</div>
            	</div>
                
                <div className="modal-body">
                    <i class="fas fa-exclamation-circle"></i>
                    <div class='modalTitle'></div>

                    <div className="row">
                        <div className="col-md-6">
                            <div id="cancel btnNo" className="btn btn-red cancel">Annuleren</div>
                        </div>
                        <div className="col-md-6">
                            <button id='btnYes' className="btn btn-green">OK</button>
                        </div>
                    </div>
				</div>
			</div>
        </div>
	)
}

export default ModalDelete;