import React, { Component } from 'react';
import withAuth from '../components/Login/withAuth';
import ReactHlsPlayer from 'react-hls-player'

class Hikvision extends Component {

  render() {
    return (
      <React.Fragment>
        <h2>HikVision Test</h2>

        <div>
          <ReactHlsPlayer
            src='http://localhost:8000/streams/output.m3u8'
            autoPlay={true}
            controls={true}
            width={640}
            height={480}
            muted="muted"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withAuth(Hikvision);
