import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const RoomSelector = (props) => {
    const {t} = useTranslation();
    const [keplerrooms, setKeplerrooms] = useState([]);
    const [keplerfloors, setKeplerfloors] = useState([]);
    const [loadingScreen, setLoadingScreen] = useState(true);
    var groupsArray = [];
    var floorsArray = [];

    const getKeplerRooms = async() => {
        setLoadingScreen(true);

        const [rooms, roomdevices] = await Promise.all([
            import("../data/rooms"),
            import("../data/devices")
        ]) 
        
        const roomsData = await rooms.getRoomsByLocation(localStorage.getItem('currentLocation'));
        const nightnurserooms = (await Promise.all(roomsData.map(async room => {
            const devices = await rooms.getDevicesofRoom(room.roomid);
            const keplerParams = (await Promise.all(devices.map(async device => await roomdevices.getDeviceConfig(device.deviceid)))).flatMap(params => params.filter(param => param.parametername === "KeplerID"));
            room.haskepler = keplerParams.length > 0 ? 1 : 0;

            const valueExists = floorsArray.some(floor => floor.id === room.relatedid);
            
            if(keplerParams.length > 0 && !valueExists){
                floorsArray.push({
                    id: room.relatedid,
                    name: room.relatedname
                })
            }

            return room.haskepler ? room : null;
        }))).filter(Boolean);

        setKeplerfloors(floorsArray);
        setKeplerrooms(nightnurserooms);
        setLoadingScreen(false);
    }

    const handleAllChecked = () => {
        document.querySelectorAll('.checkroom').forEach(check => check.checked = true);
    }

    const addGroupstoStorage = async (event, groupid, type) => {
        event.target.checked ? groupsArray.push({ id: groupid, type: type }) : groupsArray = groupsArray.filter(id => id !== groupid);
    }

    const addSelectedGroups = async (selectgroups) => {
        selectgroups && selectgroups.length > 0 ? localStorage.setItem('groups', JSON.stringify(selectgroups)) : localStorage.removeItem('groups');
        window.location.reload();
    }

    useEffect(() => {
        getKeplerRooms();
    }, [])

    return(
        <div className="select-queues-groups">
            <div className="list-queue-groups">

                {loadingScreen && (<div className="loadingScreen" style={{ position: 'static', textAlign: 'center'}}><div className="loader" style={{ position: 'static', margin: 'auto' }} ></div><span style={{ display: 'block', marginTop: '20px' }}>Kamers worden geladen</span></div>)}

                <div className="row">
                    <div className="col-md-6">
                        <strong>{t('floors')}</strong>
                        <table with="100%" className="table responsive nowrap">
                            <thead style={{ display: 'none' }}>
                                <tr>
                                    <th><input type="checkbox" onClick={handleAllChecked.bind(this)} /></th>
                                    <th>Verdieping</th>
                                </tr>
                            </thead>
                            <tbody>
                                {keplerfloors.map((floor, i) => {
                                    return(
                                        <tr>
                                            <td><input type="checkbox" onClick={(event) => addGroupstoStorage(event, floor.id, 'floor')} className="checkroom" checkedname="checkroom"/></td>
                                            <td>{floor.name}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6">
                        <strong>{t('rooms')}</strong>
                        <table with="100%" className="table responsive nowrap">
                            <thead style={{ display: 'none' }}>
                                <tr>
                                    <th><input type="checkbox" onClick={handleAllChecked.bind(this)} /></th>
                                    <th>Kamer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {keplerrooms.map((room, i) => {
                                    return(
                                        <tr>
                                            <td><input type="checkbox" onClick={(event) => addGroupstoStorage(event, room.roomid, 'room')} className="checkroom" checkedname="checkroom"/></td>
                                            <td>{room.roomname}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                
                <button className="btn btn-blue" style={{ float: 'none' }} onClick={() => addSelectedGroups(groupsArray)}>{t('confirm')}</button>
            </div>
        </div>
    )
}