import Config from "../config.json";

export const getTabs = () => {
    return fetch(Config.API_URL + `/mba/cards/tabs`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('GUID'),
        }
    })
        .then(response => response.json())
        .then(tabs => {
            return tabs;
        })
}

export const getCardsOfTab = (tabid) => {
    return fetch(Config.API_URL + `/mba/cards/tabs/${tabid}`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "tabid": tabid
        }
    })
        .then(response => response.json())
        .then(cards => {
            return cards;
        })
}

export const getParamsOfCard = (cardid) => {
    return fetch(Config.API_URL + `/mba/cards/${cardid}/parameters`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "cardid": cardid
        }
    })
        .then(response => response.json())
        .then(params => {
            return params;
        })
}

export const insertTab = (name) => {
    return fetch(Config.API_URL + `/mba/cards/tabs/insert`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "tabname": name
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const deleteTab = (tabid) => {
    return fetch(Config.API_URL + `/mba/cards/tabs/${tabid}/delete`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "tabid": tabid
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const sortTab = (tabid, sort) => {
    return fetch(Config.API_URL + `/mba/cards/tabs/${tabid}/sort`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "tabid": tabid,
            "sort": sort
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const insertCard = (cardName, tabid, cardSize) => {
    return fetch(Config.API_URL + `/mba/cards/insert`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "name": cardName,
            'tabid': tabid,
            'cardsize': cardSize,
        }
    })
        .then(response => response.json())
        .then(cards => {
            return cards;
        })
}

export const getLatestCard = () => {
    return fetch(Config.API_URL + `/mba/cards/latest/`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
        }
    })
        .then(response => response.json())
        .then(card => {
            return card;
        })
}

export const insertParamInCard = (cardid, param, value) => {
    return fetch(Config.API_URL + `/mba/cards/${cardid}/insert/parameter`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "cardid": cardid,
            "parameter": param,
            "value": value
        }
    })
        .then(response => response.json())
        .then(params => {
            return params;
        })
}

export const deleteCard = (cardid) => {
    return fetch(Config.API_URL + `/mba/cards/${cardid}/delete`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "cardid": cardid
        }
    })
        .then(response => response.json())
        .then(card => {
            return cardid;
        })
}

export const deleteParamsOfCard = (cardid) => {
    return fetch(Config.API_URL + `/mba/cards/${cardid}/delete/parameters`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "cardid": cardid
        }
    })
        .then(response => response.json())
        .then(card => {
            return cardid;
        })
}

export const sortCard = (cardid, sort) => {
    fetch(Config.API_URL + `/mba/cards/${cardid}/sort`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "cardid": cardid,
            "sort": sort
        }
    })
        .then(response => response.json())
        .then(card => {
            return card;
        })
}

export const changeSoundLevel = (object, value) => {
    fetch(Config.API_URL + `/settings/soundlevel/`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "objectid": object,
            "soundlevel": value
        }
    })
        .then(response => response.json())
        .then(sound => {
            return sound;
        })
}

export const enableAlarm = (alarmid, message) => {
    fetch(Config.API_URL + `/settings/enablealarm/`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "objectid": alarmid,
            "alarmtype": message
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const disableAlarm = (alarmid, message) => {
    fetch(Config.API_URL + `/settings/disablealarm/`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "objectid": alarmid,
            "alarmtype": message
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const resetDashboard = (employeeid, name) => {
    fetch(Config.API_URL + `/mba/resetdashboard/${employeeid}`, {
      "method": "GET",
      "headers": {
        "Authorization": "Bearer " + localStorage.getItem('id_token'),
        "Content-Type": "application/json",
        "userid": localStorage.getItem('UserID'),
        "customerid": localStorage.getItem('CustomerID'),
        "employeeid": employeeid,
        "employeename": name
      }
    })
      .then(response => response.json())
      .then(message => {
        return message;
      })
}