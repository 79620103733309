import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import { acceptAlarm, cancelAlarm, finishAlarm, getCallOfAlarm } from '../../data/alarms';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LocationAlert from '../../components/LocationAlert';
import { useTranslation } from 'react-i18next';
import { confirmDelete } from '../../Functions.js';
import ModalDelete from '../../components/ModalDelete.js';
import AlarmNotes from '../../components/AlarmNotes.js';
moment.locale('nl');
const $ = require('jquery');

const AlarmDetail = (props) => {
  const { t } = useTranslation();
  const [alarm, setAlarm] = useState([]);
  const [teams, setTeams] = useState([]);
  const [route, setRoute] = useState([]);
  const [logs, setLogs] = useState([]);
  const [tabindex, setTabindex] = useState(0);
  const [currenttab, setCurrenttab] = useState(0);
  const [showEmployees, setShowEmployees] = useState(false);
  const [showRoute, setShowRoute] = useState(false);
  const [isLoggedInDevice, setIsLoggedInDevice] = useState(false);
  const [showNotLoggedIn, setShowNotLoggedIn] = useState(false);
  var currentAlarmID = props.router.params.id;

  const setCall = (event, alarmid) => {
    var btn = event.target;

    if ($(btn).is(':checked')) {
      this.activateCall(alarmid);
    } else {
      this.deactivateCall(alarmid);
    }
  }

  const getData = async () => {
    const [reports, alarms, objects, routes, settings] = await Promise.all([
      import("../../data/reports.js"),
      import("../../data/alarms.js"),
      import("../../data/objects.js"),
      import("../../data/routes.js"),
      import("../../data/settings.js")
    ])

    var notification = currentAlarmID.includes("N");
    var alarm = notification ? currentAlarmID.split("N")[1] : currentAlarmID;
    var deviceLoggedIn = await objects.isLoggedInOnDevice();
    setIsLoggedInDevice(deviceLoggedIn);
    
    const data = notification ? await alarms.getNotification(alarm) : await alarms.getAlarm(alarm);
    setAlarm(data[0]);

    const alarmemployees = await settings.getAlarmEmployees();
    setShowEmployees(alarmemployees[0].value == 1 ? true : false);

    if(!notification){
      getCallOfAlarm(alarm);
    }

    const teamslogs = await reports.getTeamsLogsOfAlarm(alarm);
    setLogs(teamslogs);

    const historyalarm = await reports.getHistoryAlarms(currentAlarmID);
    var alarmid = historyalarm[0].objectalarmid ? historyalarm[0].objectalarmid : currentAlarmID;
    var alarmroute = historyalarm[0].route;
    var dateOfAlarm = moment(historyalarm[0].uptriggered).format("YYYY-MM-DD HH:mm:ss");

    const getalarmroute = await routes.getStep(alarmroute);
    setRoute(getalarmroute);

    const getteams = await reports.getTeamsOfAlarms(alarmid);

    console.log(getteams);

    const logsAndUsers = await Promise.all([
      Promise.all(getteams.map(async team => await reports.getLogOfTeam(team.objectalarmteamid, alarmid))),
      Promise.all(getteams.map(async team => await reports.getUsersOfAlarm(alarmid, dateOfAlarm, team.teamid)))
    ]);
  
    const teamsArray = getteams.map((team, index) => {
      team['log'] = logsAndUsers[0][index];
      team['users'] = logsAndUsers[1][index];
      return team;
    });

    setTeams(teamsArray);
  }

  useEffect(() => {
    getData();
  }, [])

  $('.btn-camera').on('click', function () {
    var host = $(this).data('cam-host');
    var $rand;

    if (host) {
      $('.alarmcamera').fadeIn();
      $('.alarmcamera').append('<img class="CamPic" src="http://' + host + '/record/current.jpg?rand=1" />');

      $rand = 1;
      setInterval(function () {
        $rand = $rand + 1
        $('.CamPic').attr('src', 'http://' + host + '/record/current.jpg?rand=' + $rand);
      }, 100);
    }

  });

  var nameAccepted = '';
  var nameFinished = '';
  var nameCanceled = '';
  var teamAccepted = '';
  var teamFinished = '';
  var teamCanceled = '';

  return (
    <React.Fragment>
      <LocationAlert />

      <div className='loc_content'>
        <div class="actions" style={{ float: 'right', marginTop: '0px', marginRight: '40px' }}>
          {!alarm.finishtriggered && alarm.downtriggered !== null ? <button onClick={() => isLoggedInDevice.loggedin === true ? finishAlarm(currentAlarmID, alarm.objectalarmteamid, isLoggedInDevice.deviceid) : setShowNotLoggedIn(true) } class="btn btn-finish">
            <i class="fas fa-thumbs-up"></i> {t('handle')}
          </button> : ''}

          {!alarm.downtriggered && alarm.uptriggered ? <button onClick={() => isLoggedInDevice.loggedin === true ? acceptAlarm(currentAlarmID, alarm.objectalarmteamid) : setShowNotLoggedIn(true) } class="btn btn-accept">
            <i class="fas fa-check-circle"></i> {t('accept')}
          </button> : ''}

          {!alarm.downtriggered && alarm.uptriggered ? <button onClick={() => isLoggedInDevice.loggedin === true ? cancelAlarm(currentAlarmID, alarm.objectalarmteamid) : setShowNotLoggedIn(true) } class="btn btn-ignore">
            <i class="fas fa-times-circle"></i> {t('cancel')}
          </button>  : '' }

          {alarm.downtriggered && alarm.sensorhost ? <button class="btn btn-camera" data-alarmid={currentAlarmID} data-cam-host={alarm.sensorhost}>
            <i class="fas fa-video"></i> {t('camera')}
          </button> : ''}

          <strong className="call">{t('voicecall')}:</strong> <input onChange={(event) => { setCall(event, alarm.id) }} type="checkbox" name="call" className="call check-call" />
        </div>
        <h1><button className="goBackBtn" onClick={() => window.history.back()}><i class="fas fa-arrow-circle-left"></i></button> {alarm.object} - {t(`${alarm.slug}`)} {alarm.device ? "(" + alarm.device + ")" : ''} - {moment(alarm.uptriggered).format('DD-MM-YYYY HH:mm:ss')}</h1>

        {showNotLoggedIn && (<div style={{ display: 'block' }} className="alert alert-danger">Je bent niet ingelogd op een apparaat. Hierdoor kun je geen alarmen in behandeling nemen of afhandelen.</div>)}
        <div className='col-md-12'>
          <div className="status">
            <h2>{t('status')} <i class="route-info fas fa-info-circle" onClick={() => setShowRoute(true)}></i></h2>
            {showRoute && (<div className='alarm-route'>
              <i class="closeroute fas fa-times-circle" onClick={() => setShowRoute(false)}></i>
              <h2>{t('route')}</h2>

              {route.map((route, i) => {
                return (
                  <div className='routeteam'>
                    <div className='team'>
                      <i class="fas fa-users"></i>
                      {route.teamname}
                      <span className='teamsequence'>
                        {route.sequencetimeout} {t('seconds')}
                      </span>
                    </div>
                    <div className='sequence-arrow'>
                      <i class="fas fa-arrow-right"></i>
                    </div>
                  </div>
                )
              })}
            </div> )}
            <div className="status-info">
              <div className="status-teams">
                {teams.map((team, i) => {
                  var teamReceived = 0;

                  team.log.forEach(function (log) {
                    if (log.responsecontent === "receive") {
                      teamReceived += 1;
                    }
                    if (log.responsecontent === "accept") {
                      nameAccepted = log.name;
                      teamAccepted = team.name;
                    }

                    if (log.responsecontent === "confirm") {
                      nameFinished = log.name;
                      teamFinished = team.name;
                    }

                    if (log.responsecontent === "cancel") {
                      nameCanceled = log.name;
                      teamCanceled = team.name;
                    }
                  });

                  return (
                    <div className='status-team'>
                      <div className='team'>
                        <i className={`${teamReceived > 0 ? 'teamReceived' : 'teamNotReceived'} fas fa-users`}></i>
                        <span class={`badge-pill ${teamReceived > 0 ? 'teamReceived' : 'teamNotReceived'}`}>{team.name}</span>
                      </div>

                      <div className='sequence-arrow'>
                        <i class="fas fa-arrow-right"></i>
                      </div>
                    </div>)
                })}
              </div>
              <div className="border-divider"></div>
              <div className='status-accepted'>
                <i className={`fas ${alarm.downtriggered ? 'fa-check-circle' : 'fa-times-circle'}`}></i>
                <span>{t('accepted')} <div className={`${alarm.downtriggered ? 'showAcceptedName' : 'hideAcceptedName'}`}>{nameAccepted} - {teamAccepted} <br />{t('on')} {(alarm.downtriggered ? moment(alarm.downtriggered).format('DD-MM-YYYY HH:mm:ss') : '')}</div></span>
              </div>
              <div className="border-divider"></div>
              <div className='status-finished'>
                <i className="fas fa-flag-checkered" style={{ color: alarm.finishtriggered ? '#009f07' : '#c7cad9' }}></i>
                <span>{t('solved')} <div className={alarm.finishtriggered ? 'showAcceptedName' : 'hideAcceptedName'}>{alarm.finishtriggered ? nameFinished + ' - ' + teamFinished : ''} <br /> {t('on')} {moment(alarm.finishtriggered).format('DD-MM-YYYY HH:mm:ss')}</div></span>
              </div>
            </div>
          </div>
        </div>

        <div class="alarmcamera">
          <h2 class="camTitle">{t('camera')} {alarm.object}</h2>
        </div>

        <div className="row">
          <div class="col-md-12">

          <Tabs selectedIndex={tabindex} onSelect={index => {
            setTabindex(index);
            setCurrenttab(index);
          }}>
            <TabList>
              <Tab data-id="1">{t('alarmlogs')}</Tab>
              {showEmployees && (<Tab data-id="2">{t('loggedinemployees')}</Tab>)}
              <Tab data-id="3">{t('notes')}</Tab>
            </TabList>

            <TabPanel>
              <div className="alarm-logs" style={{ background: '#ffffff', margin: '20px 40px', padding: '30px' }}>
                <table width="100%" style={{ tableLayout: 'fixed' }}>
                  {teams.map((team, i) => {
                    return (
                      <tr>
                        <td valign="top" style={{ paddingTop: '14px', width: '20%' }}>{team.name}</td>
                        <td valign="top">
                          <table width="100%" style={{ tableLayout: 'fixed' }}>
                            {team.log.map((log, i) => {

                              var response = '';

                              if (log.responsecontent === "accept") {
                                response = t('accepted');
                              } else if (log.responsecontent === "confirm") {
                                response = t('confirmed');
                              } else if (log.responsecontent === "receive") {
                                response = t('received');
                              } else if (log.responsecontent === "ignored") {
                                response = t('ignored');
                              } else if (log.responsecontent === "declined") {
                                response = t('declined');
                              } else if (log.responsecontent === "opened") {
                                response = t('opened');
                              } else if (log.responsecontent === "camera opened") {
                                response = t('cameraopened');
                              }

                              return (
                                <tr>
                                  <td>{log.name}</td>
                                  <td>{response}</td>
                                  <td>{moment(log.responsedon).format('DD-MM-YYYY HH:mm:ss')}</td>
                                </tr>
                              )
                            })}
                          </table>
                        </td>
                      </tr>
                    )
                  })}
                </table>
              </div>
            </TabPanel>
            
            {showEmployees && (<TabPanel>
                <div className="alarm-employees">
                  <table width="100%" style={{ tableLayout: 'fixed' }}>
                    {teams.map((team, i) => {
                      return (
                        <tr>
                          <td valign="top" style={{ paddingTop: '14px' }}>{team.name}</td>
                          <td valign="top">
                            <table width="100%" style={{ tableLayout: 'fixed' }}>
                              {team.users && team.users.length > 0 ? team.users.map((user, i) => {
                                return (
                                  <tr>
                                    <td>{user.user}</td>
                                  </tr>
                                )
                              }) : 'Geen users gevonden'}
                            </table>
                          </td>
                        </tr>
                      )
                    })}
                  </table>
                </div>
            </TabPanel>)}
            
            <TabPanel>
              {alarm && alarm.objectid ? <AlarmNotes alarm={alarm} /> : ''}
            </TabPanel>
        </Tabs>
            

            
          </div>

          <ModalDelete />
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AlarmDetail);
