import React, { useEffect, useState } from 'react';
import withAuth from '../Login/withAuth';
import Config from "../../config.json";
const $ = require('jquery');

const TipsBlock = () => {
  const [currentTip, setCurrentTip] = useState('');
  const [tips, setTips] = useState([]);

  const getData = async () => {
    const tipsData = await import("../../data/tips");
    const data = await tipsData.getTips();
    setTips(data && data.length > 0 ? data : []);
    setCurrentTip(data[Math.floor(Math.random()*data.length)]);
  }

  useEffect(() => {
    getData();

    const tipsblockFront = document.querySelector('.tipsblock-front');
    const tipsblockBack = document.querySelector('.tipsblock-back');

    tipsblockFront.style.display = 'block';
    tipsblockBack.style.display = 'none';

    function toggleTips() {
      tipsblockFront.style.display = (tipsblockFront.style.display === 'block') ? 'none' : 'block';
      tipsblockBack.style.display = (tipsblockBack.style.display === 'block') ? 'none' : 'block';
    }

    tipsblockFront.addEventListener('click', toggleTips);
    tipsblockBack.addEventListener('click', toggleTips);
  }, []);

  return (
    <React.Fragment>
      <div className='tipsblock'>
        <div className='tipsblock-front'>
          <div className='front-left-top'></div>
          <div className='front-right-top'></div>

          <h3>{currentTip && currentTip.fronttext ? currentTip.fronttext : ''}</h3>

          <span><i className={currentTip && currentTip.moduleicon? currentTip.moduleicon : ''}></i> {currentTip && currentTip.modulename ? currentTip.modulename : ''}</span>

          <div className='front-left-bottom'></div>
          <div className='front-right-bottom'></div>
        </div>
        <div className='tipsblock-back'>
          <div className='top'></div>
          <div className='right'></div>
          <h3>{currentTip && currentTip.backtext ? currentTip.backtext : ''}</h3>
          <div className='bottom'></div>
          <div className='left'></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withAuth(TipsBlock);