import React, { Component } from 'react';
import withRouter from '../../components/withRouter';
import AuthService from '../Login/AuthService';
const Auth = new AuthService();

const LoggedInUser = (props) => {

    const handleLogout = () => {
        Auth.logout()
        window.location.href = '/';
    }

    var name = props.user.data ? (props.user && props.user.data ? props.user.data[0].name : null) : (props.user && props.user.UserInfo ? props.user.UserInfo.name : null);

    return (
        <React.Fragment>
            
            <div className="user-name"><i class="fa-solid fa-circle-user"></i> {name}</div>
            <span className="header-icon logout-icon" onClick={handleLogout.bind(this)}><i className="icon fa fa-sign-out-alt"></i></span>
        </React.Fragment>
    );
}

export default withRouter(LoggedInUser);
