import Config from "../config.json";
import axios from 'axios';

export const getVideos = () => {
  return fetch(Config.API_URL + "/mba/settings/video/get", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const uploadVideo = (filename, data) => {
  return axios.post(Config.API_URL + "/mba/settings/video/upload", data, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      'filename': filename
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const insertVideo = (name, type) => {
  return fetch(Config.API_URL + "/mba/settings/video/insert", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "name": name,
      "type": type
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const deleteVideo = (filename) => {
  return fetch(Config.API_URL + "/mba/settings/video/delete", {
    "method": "DELETE",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      'filename': filename
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const sortVideo = (videoid, sort) => {
  return fetch(Config.API_URL + `/mba/settings/video/${videoid}/sort`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "videoid": videoid,
      "sort": sort
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}