export const msalConfig = {
    auth: {
    //   clientId: "79a374fa-5412-4874-878d-0d81d6c80173",
      clientId: "a693d5eb-3ca1-446c-bf4f-6fdba9ee5b93",
      authority: "https://login.microsoftonline.com/41c48741-f1c7-4c37-bfad-a1caf6d6f03a", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };