import React, { useEffect, useState } from 'react';
import withRouter from '../components/withRouter';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
moment.locale('nl');

const VirtualRoundCard = (props) => {
    const { t } = useTranslation();
    const [room, setRoom] = useState([]);
    const [alarmcolor, setAlarmcolor] = useState('rgb(92, 184, 92)');

    const getData = async () => {
        const [rooms, roomdevices, nightnurse, objects] = await Promise.all([
            import("../data/rooms"),
            import("../data/devices"),
            import("../data/nightnurse"),
            import("../data/objects"),
        ])

        const detectionsArray = [
            { label: "NO_DETECTION", text: t('nodetection'), icon: ''},
            { label: "MAN_DOWN_DETECTION", text: t('falling'), icon: 'falling.png' },
            { label: "SOEB_DETECTION", text: t('soeb'), icon: 'soeb.png' },
            { label: "OUT_OF_BED_DETECTION", text: t('outofbed'), icon: 'outofbed.png' },
            { label: "OUT_OF_ROOM_DETECTION", text: t('outofroom'), icon: 'outofroom.png' },
            { label: "IN_BED_DETECTION", text: t('inbed'), icon: 'inbed.png' },
            { label: "IN_BATHROOM_DETECTION", text: t('inbathroom'), icon: 'inbathroom.png' },
            { label: "MISSING_BED_DETECTION", text: t('nobed'), icon: 'nobed.png' },
            { label: "PERSON_VISIBLE_DETECTION", text: t('personvisible'), icon: '' },
            { label: "PERSON_GETTING_UP_FROM_CHAIR_DETECTION", text: t('standingfromchair'), icon: 'standingfromchair.png' },
            { label: "PERSON_GETTING_UP_IN_BED_DETECTION", text: t('sittinginbed'), icon: 'sittinginbed.png' },
            { label: "STAFF_ENTERING_ROOM_DETECTION", text: t('employee'), icon: 'staff.png' },
            { label: "CAMERA_UNREACHABLE_DETECTION" ,text: t('cameraunreachable'),  icon: 'camera-unreachable.png' },
            { label: "HALLWAY_WANDER_DETECTION", text: t('wandering'), icon: 'wandering.png' }
        ];
    
        const getStatusTime = (datetimeQueue) => {
            var date_in_queue = moment(datetimeQueue);
            var date_now = moment();
            return moment.utc(moment(date_now,"DD/MM/YYYY HH:mm:ss").diff(moment(date_in_queue,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss");
        }

        var roomsArray = [];
        const roomData = await rooms.getRoom(props.roomid);

        const hasRoomKepler = async (roomid) => {
            const devices = await rooms.getDevicesofRoom(roomid);
            const keplerParams = await Promise.all(devices.map(async device => await roomdevices.getDeviceConfig(device.deviceid)));
            const hasKepler = keplerParams.some(params => params.some(param => param.parametername === "KeplerID"));

            if(hasKepler){
                return true;
            }

            return false;
        }

        const roomstatus = await nightnurse.getRoomLatestStatus(props.roomid);

        var item = {
            room: roomData[0], 
            hasKepler: await hasRoomKepler(props.roomid), 
            alarms: await objects.getObjectAlarms48hours(props.roomid),
            status: roomstatus && roomstatus.length > 0 ? roomstatus[0] : ''
        }

        const currentStatus = detectionsArray.filter(type => type.label === item.status.state);
        const currentStatusTime = getStatusTime(roomstatus && roomstatus.length > 0 ? moment(roomstatus[0].time).utcOffset("+0000").format("YYYY-MM-DD HH:mm:ss") : moment().format("YYYY-MM-DD HH:mm:ss"));

        roomsArray.push({
            roomname: item.room.roomname,
            status: currentStatus && currentStatus.length > 0 ? currentStatus[0].text : t('nostatus'),
            icon: currentStatus && currentStatus.length > 0 ? currentStatus[0].icon : '',
            duration: currentStatusTime,
            critical: item.alarms,
            alarmsred: item && item.alarms && item.alarms[0] ? (item.alarms[0].downtriggered === null && item.alarms[0].finishtriggered === null ? 1 : 0) : 0,
            alarmsorange: item && item.alarms && item.alarms[0] ? (item.alarms[0].downtriggered !== null && item.alarms[0].finishtriggered === null ? 1 : 0) : 0
        });

        if(roomsArray[0].alarmsred > 0){
            setAlarmcolor('#ff0000');
        }else if(roomsArray[0].alarmsorange > 0){
            setAlarmcolor('#FFA500');
        }else{
            setAlarmcolor('rgb(92, 184, 92)');
        }

        setRoom(roomsArray[0]);
    }

    useEffect(() => {
        setInterval(() => getData(), 1000)
    }, [])
    
    return(
        <React.Fragment>
            <div class="card round-card" style={{ borderLeft: "solid 5px " + alarmcolor }}>
                <h4 className='room' style={{ padding: "10px 15px", textAlign: 'center', fontSize: '18px', fontWeight: '500' }}>{room.roomname}</h4>
                {room.icon ? <img src={`/images/icons/kepler/${room.icon}`} style={{ height: '100px', width:'auto', margin: 'auto', display: 'block' }}/> : <i className='fa fa-close noStatus'></i>}
                <div className='statustext' style={{ padding: "10px 15px 0 15px", fontSize: '16px', textAlign: 'center', marginTop: '20px', fontWeight: '400' }}><span>{room.status}</span></div>
                <div className='time' style={{ padding: "0px 15px 10px 15px", fontSize: '14px', textAlign: 'center', marginTop: '5px' }}><span>{room.duration}</span></div>
                <div className='criticalalert' style={{ padding: "10px 0px", textAlign: 'center', fontWeight: '300'}}>
                    <span class="criticalalarms" style={{ fontSize: '16px', marginRight: "10px" }}><i style={{ color: room && room.critical ? (room.critical.length > 0 ? "#ff0000" : "rgb(92, 184, 92)") : "rgb(92, 184, 92)" }} className={ room && room.critical ? (room.critical.length > 0 ? 'fas fa-exclamation-triangle' : 'fa-solid fa-check') : 'fa-solid fa-check'}></i></span>
                    {room && room.critical ? (room.critical.length > 0 ? t('lastcriticalincident') + ': ' + room.critical[0].status + ' - ' + moment(room.critical[0].uptriggered).format("DD-MM-YYYY HH:mm") : t('nocriticalincident48hours')) : t('nocriticalincident48hours')}
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(VirtualRoundCard);
