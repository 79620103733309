import React, { Component } from 'react';
import withAuth from '../../components/Login/withAuth';
import { NavLink } from "react-router-dom";
import "../mba.css";
import moment from 'moment';

const Menu = () => {
    return(
        <React.Fragment>
            <div className="mbamenu">
                <NavLink to="/mba-admin/status" exact="true">Status</NavLink>
                <NavLink to="/mba-admin/responserules" exact="true">Responserules</NavLink>
                <NavLink to="/mba-admin/timetables" exact="true">Timetables</NavLink>
                <NavLink to="/mba-admin/messages" exact="true">Messages</NavLink>
                <NavLink to={`/mba-admin/log/${moment().format('YYYY-MM-DD')}`} exact="true">Log</NavLink>
            </div>
        </React.Fragment>
    )
}

export default withAuth(Menu);