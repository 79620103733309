import Config from "../config.json";

export const AuthVerification = () => {
    return fetch(Config.API_URL + "/auth/verification", {
        "method": "POST",
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const APIStatus = () => {
    return fetch(Config.API_URL + "/settings/status", {
        "method": "GET",
    })
        .then(response => response.json())
        .then(data => {
            return data;
        }, err => {
            return err;
        })
}

export const QRLogin = () => {
    return fetch(Config.API_URL + "/mba/settings/login/qr", {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}