import React, { useState, useEffect } from 'react';

const LoadingScreen = (props) => {
    const [showLoadingScreen, setShowLoadingScreen] = useState(false);

    useEffect(() => {
        setShowLoadingScreen(props.state === true ? true : false);
    })

  return (
    <React.Fragment>
        {showLoadingScreen && (<div className="loadingScreen"><div className="loader"></div></div>)}
    </React.Fragment>
  )
};

export default LoadingScreen;
