import Config from "../config.json";

export const Authenticate = () => {
    return fetch(Config.KEPLER + "/auth/login", {
        "method": "POST",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/json",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getLocations = async() => {
    return fetch(Config.KEPLER + "/nightnurse/locations", {
        "method": "GET",
        "headers": {
            "token": localStorage.getItem('nightnursetoken')
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getKeplerStreams = async(location) => {
    return fetch(Config.KEPLER + "/nightnurse/streams", {
        "method": "GET",
        "headers": {
            "token": localStorage.getItem('nightnursetoken'),
            "location": location,
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const updateKeplerConfig = (streamid, streamname, data) => {
    return fetch(Config.KEPLER + "/nightnurse/stream/config", {
        "method": "PUT",
        "headers": {
            "token": localStorage.getItem('nightnursetoken'),
            "Content-Type": "application/json",
            "streamid": streamid
        },
        "body": data
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getKeplerDevicesOfRoom = (roomid) => {
    return fetch(Config.API_URL + "/nightnurse/devices/", {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('nightnursetoken'),
            "Content-Type": "application/json",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "roomid": roomid
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getStreamActivities = (streamid, streamname, reportdate) => {
    return fetch(Config.API_URL + "/nightnurse/client/streams/" + streamid + "/activities", {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('nightnursetoken'),
            "Content-Type": "application/json",
            "streamid": streamid,
            "reportdate": reportdate
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getKeplerActivities = async(streamid, reportdate) => {
    return fetch(Config.KEPLER + "/nightnurse/stream/activities", {
        "method": "GET",
        "headers": {
            "token": localStorage.getItem('nightnursetoken'),
            "streamid": streamid,
            "reportdate": reportdate
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getKeplerWeekActivities = async(streamid, reportdate) => {
    return fetch(Config.KEPLER + "/nightnurse/stream/activities/week", {
        "method": "GET",
        "headers": {
            "token": localStorage.getItem('nightnursetoken'),
            "streamid": streamid,
            "reportdate": reportdate
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getKeplerActivitiesTimes = async(streamid, reportdate) => {
    return fetch(Config.KEPLER + "/nightnurse/reports/stream/times", {
        "method": "GET",
        "headers": {
            "token": localStorage.getItem('nightnursetoken'),
            "streamid": streamid,
            "reportdate": reportdate
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getKeplerTopAlarms = async(streamid, reportdate) => {
    return fetch(Config.KEPLER + "/nightnurse/reports/stream/topalarms", {
        "method": "GET",
        "headers": {
            "token": localStorage.getItem('nightnursetoken'),
            "streamid": streamid,
            "reportdate": reportdate
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getRoomLatestStatus = async(roomid) => {
    return fetch(Config.KEPLER + "/nightnurse/room/status", {
        "method": "GET",
        "headers": {
            "token": localStorage.getItem('nightnursetoken'),
            "roomid": roomid
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}