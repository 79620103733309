import React, { Component, useEffect, useState } from 'react';
import './Settings.css';
import withAuth from '../components/Login/withAuth';
import { crudAlertControl, initDataTable } from "../Functions.js";
import LocationAlert from '../components/LocationAlert';
import { useTranslation } from 'react-i18next';

const SettingsTransmitters = () => {
    const { t } = useTranslation();
    const [transmitters, setTransmitters] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [valueExists, setValueExists] = useState(false);

    const showError = (text) => {
        const errMsgElement = document.querySelector('.err_msg');
        errMsgElement.textContent = text;
        errMsgElement.style.display = 'block';
    }

    const hideError = () => {
        const errMsgElement = document.querySelector('.err_msg');
        errMsgElement.textContent = '';
        errMsgElement.style.display = 'none';
    }

    const switchDevice = async (event, deviceid, devicename) => {
        const [objects, alarms] = await Promise.all([
            import("../data/objects.js"),
            import("../data/alarms.js")
        ]) 

        const transmitter = transmitters.find(transmit => transmit.deviceid === deviceid);
        const transmitterAlarms = await alarms.getAlarms().then(alarm =>
            alarm.filter(obj => obj.objectid === transmitter.objectid)
        );

        if(transmitter){
            if (transmitter.alarms.length === 0) {
                hideError();
                const changeData = await objects.changeDeviceOfObject(deviceid, devicename, event.target.value);
                crudAlertControl('update', changeData);
            } else {
                showError(`Er staan nog alarmen open op ${transmitter.object}. Handel eerst deze alarmen af.`)
            }
        }
    }

    const saveValue = async (event, deviceid, devicename) => {
        console.log(deviceid);
        const devices = await import("../data/devices.js");
        var value = event.target.parentNode.firstChild.value;
        const exists = transmitters.some(transmit => transmit.value === value);

        if (!exists) {
            hideError();
            const data = await devices.updateNeckTrans(deviceid, devicename, value);
            crudAlertControl('update', data);
        } else {
            showError("De ingevoerde waarde bestaat al. Voer een andere waarde in.");
        }
    }

    const getData = async () => {
        const [rooms, devices] = await Promise.all([
            import("../data/objects.js"),
            import("../data/devices.js")
        ])

        const roomsData = await rooms.getObjects();
        setRooms(roomsData);

        const data = await devices.getNeckTrans();
        setTransmitters(data);

        initDataTable(); 
    };

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className="loc_content">
                <h1>{t('necktransmitters')}</h1>

                <div className="alert alert-success alert-update" role="alert">
                    {t('updatetransmitter')}
                </div>

                <div class="alert alert-danger" role="alert">
                    {t('somethingwrong')}
                </div>

                <div className="err_msg" style={{ color: '#333', marginLeft: '40px', marginRight: '40px', width: 'auto' }}></div>

                <div className="DataTable_Container">
                    <table id="DataTable" class="table responsive nowrap sortTable" width="100%">
                        <thead>
                            <tr class="rowtitle">
                                <th>{t('name')}</th>
                                <th>{t('location')}</th>
                                <th>{t('mac')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transmitters.map((transmit, i) => {
                                console.log(transmit);
                              return (
                                <tr>
                                    <td>{transmit.devicename}</td>
                                    <td data-search={transmit.object} data-order={transmit.object}>
                                        <select onChange={(event) => switchDevice(event, transmit.deviceid, transmit.devicename)} className="form-control" name="rooms" defaultValue={transmit.objectid}>
                                            <option value="0">{t('select')}</option>
                                                {rooms.map((room, i) => {
                                                    console.log(room);
                                                    return (<option value={room.objectid}>{room.objectname}</option>)
                                                })}
                                        </select>
                                    </td>
                                    <td data-search={transmit.value} data-order={transmit.value}><input className="form-control" type="text" name="macvalue" defaultValue={transmit.value} /> <i onClick={(event) => saveValue(event, transmit.deviceid, transmit.devicename)} class="btnSave fas fa-save"></i></td>
                                </tr>);
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
      );
}

export default withAuth(SettingsTransmitters);
