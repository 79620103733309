import React from 'react';
import withAuth from '../../components/Login/withAuth';
import * as moment from 'moment';
import '../../components/DataTables.css';
import LocationAlert from '../../components/LocationAlert';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
moment.locale('nl');

const RoomInsightsList = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [rooms, setRooms] = useState([]);
    const currentDate = moment().subtract(1, 'days').format('YYYY-MM-DD');

    const getData = async () => {
        const [roomsList, functions] = await Promise.all([
            import("../../data/rooms"),
            import("../../Functions")
        ])

        const roomsData = await roomsList.getRoomsByLocation(localStorage.getItem('currentLocation'));
        setRooms(roomsData);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    return(
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <h1>{t('roominsights')}</h1>

                <div className="DataTable_Container">
                <table id="DataTable" class="table responsive nowrap" width="100%">
                    <thead style={{ display: 'table-header-group', width: '100%' }}>
                        <tr class="rowtitle">
                            <th>{t('room')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rooms.map((room, i) => {

                            return (
                                <tr style={{ cursor: 'pointer' }} onClick={() => { navigate(`/reports/roominsights/${room.roomid}/${currentDate}`) }}>
                                    <td>{room.roomname}</td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            </div>
        </React.Fragment>
    )

}

export default withAuth(RoomInsightsList)