import React, { Component, useEffect, useState } from 'react';
import './Settings.css';
import '../components/DataTables.css';
import withRouter from '../components/withRouter';
import * as moment from 'moment';
import { initDataTable } from "../Functions";
import LocationAlert from '../components/LocationAlert';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const SettingsRoomDetails = (props) => {
    const { t } = useTranslation();
    const [room, setRoom] = useState([]);
    const [alarms, setAlarms] = useState([]);
    const [roomdevices, setRoomdevices] = useState([]);
    const [roomAlarms, setRoomalarms] = useState([]);
    const [devicetypes, setDevicetypes] = useState([]);
    const [devices, setDevices] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [locations, setLocations] = useState([]);
    const [logging, setLogging] = useState([]);

    const switchAlarm = async (event, alarmid, alarmname, roomname) => {
        const alarms = await import("../data/alarms.js");
        event.target.checked ? alarms.enableAlarm(alarmid, alarmname, props.router.params.id, roomname) : alarms.disableAlarm(alarmid, alarmname, props.router.params.id, roomname);
    };

    const showError = (text) => {
        const errMsgElement = document.querySelector('.err_msg');
        errMsgElement.textContent = text;
        errMsgElement.style.display = 'block';
    }

    const hideError = () => {
        const errMsgElement = document.querySelector('.err_msg');
        errMsgElement.textContent = '';
        errMsgElement.style.display = 'none';
    }

    const switchDevice = async (event, device, objectID) => {
        const [objects, devicesData] = await Promise.all([
            import("../data/objects.js"),
            import("../data/devices.js")
        ])

        const objectsData = await objects.getLocations();
        setLocations(objectsData);

      if (roomAlarms.length === 0) {
        hideError();

        const [data1, data2] = await Promise.all([
            devicesData.deviceChange(device, objectID, '10', locations[0]),
            devicesData.deviceChange(event.target.value, objectID, '', event.target.value, locations[0])
        ])

        return [data1, data2];
      } else {
        showError("Er staan nog alarmen open voor deze kamer. Handel eerst deze alarmen af.");
      }
    };

    const getData = async () => {
        const [logging, alarms, room, alarmsData, roomDevices, devices, deviceTypes, routes] = await Promise.all([
            import("../data/responserules.js").then((module) => module.getResponseRules('', props.router.params.id)),
            import("../data/alarms.js").then((module) => module.getAlarms()),
            import("../data/objects.js").then((module) => module.getRooms(props.router.params.id)),
            import("../data/objects.js").then((module) => module.getObjectAlarms(props.router.params.id)),
            import("../data/objects.js").then((module) => module.getRoomDevices(props.router.params.id)),
            import("../data/devices.js").then((module) => module.getDevices()),
            import("../data/devices.js").then((module) => module.getDeviceTypes()),
            import("../data/routes.js").then((module) => module.getRoutes())
        ]);

        setLogging(logging);
        initDataTable();
        setRoomalarms(alarms.filter(alarm => alarm.objectid === props.router.params.id));
        setRoom(room[0]);
        setAlarms(alarmsData);
        setRoomdevices(roomDevices);
        setDevices(devices);
        setDevicetypes(deviceTypes);
        setRoutes(routes);
    };

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className="loc_content">
                <div class="col-md-12">
                    <h1><button className="goBackBtn" onClick={() => window.history.back()}><i class="fas fa-arrow-circle-left"></i></button> {t('alarms')} {room.roomname}</h1>
                    <div class="orgDetails">
                        <table>
                            <tbody>
                                {alarms.map((roomalarm, i) => {
                                    var checked = roomalarm.enabled === 1 ? true : false;

                                    return (
                                        <tr key={i}>
                                            <td>{roomalarm.incomingmessagename}</td>
                                            <td>{roomalarm.outgoingmessagename}</td>
                                            <td>{roomalarm.outgoingroutename}</td>
                                            <td>
                                                <label class='switch'>
                                                    <input id={`Roomalarm_${roomalarm.alarmid}`} defaultChecked={checked} onChange={(event) => switchAlarm(event, roomalarm.alarmid, roomalarm.incomingmessagename, room.roomname)} class='AlarmtogBtn' type='checkbox' />
                                                    <span class='slider round'></span></label>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                
                <div className='col-md-12'>
                    <h1>{t('logging')} {room.roomname}</h1>
                    <div className="DataTable_Container" style={{ marginBottom: '25px' }}>
                        <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                            <thead>
                                <tr>
                                    <th>{t('alarm')}</th>
                                    <th>{t('date')}/{t('time')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logging.map((log, i) => {
                                    const dotColor = log.active === 2 ? '#FF0000' : '#00FF00';
                                    const msg = log.active === 2 ? 'uitgezet' : 'aangezet';
                                    const ruleDate = log.ruledate && log.ruledate !== '0000-00-00 00:00:00' ? moment(log.ruledate).format('DD-MM-YYYY') : 'onbekend';
                                    const ruleTime = ruleDate !== 'onbekend' ? moment(log.ruledate).format('HH:mm:ss') : 'onbekend';

                                    return (
                                        <tr>
                                            <td>{log.message}</td>
                                            <td>{msg} op {ruleDate} om {ruleTime}</td>
                                        </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="col-md-12">
                    <h1>{t('devices')} {room.roomname}</h1>
                    <div className='err_msg'></div>
                    <div class="orgDetails">
                        <table>
                            <tbody>
                                {roomdevices.map((roomdevice, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{roomdevice.devicetypename}</td>
                                            <td id={`devicetype_${roomdevice.devicetypeid}`}>
                                                <select onChange={(event) => switchDevice(event, roomdevice.deviceid, room.roomid)} value={roomdevice.deviceid} class="form-control device" data-object={room.roomid} id={`device_${roomdevice.deviceid}`}>
                                                    <option value="0">{t('select')}</option>
                                                    {devices.map((device, i) => {
                                                        return device.devicetypeid === roomdevice.devicetypeid ? (
                                                            <option key={i} value={device.deviceid}>{device.devicename}</option>
                                                        ) : null;
                                                    })}
                                                </select>
                                            </td>
                                        </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(SettingsRoomDetails);