import React, { Component, useEffect, useState } from 'react';
import './Reports.css';
import '../../components/DataTables.css';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import { initDataTable } from "../../Functions.js";
import { registerLocale } from  "react-datepicker";
import DataTable from 'datatables.net-dt';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
registerLocale('nl', nl);
moment.locale('nl');

const Receives = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [reports, setReports] = useState([]);
    var currentdate = new Date(props.router.params.reportdate);

    const getReceivesOfDate = async (date, init) => {
        const reportData = await import("../../data/reports.js");
        const data = await reportData.getReceives(date);
        
        await Promise.all(data.map(async (object, index, receive) => {
            const teams = await reportData.getReceivesTeam(object.objectalarmid);
            object['teams'] = teams;
            await Promise.all(teams.map(async (team, index, receive) => {
                const receivedby = await reportData.ReceivesRecievedBy(team.objectalarmteamid);
                team['receivedby'] = receivedby;
            }))
        }))

        setReports(data);
        initDataTable();
    }

    const goToDate = async (date) => {
        let table = new DataTable('#DataTable').destroy();
        navigate('/manage/reports/receives/' + moment(date).format('YYYY-MM-DD'));
        await getReceivesOfDate(moment(date).format('YYYY-MM-DD'));
    }

    useEffect(() => {
        getReceivesOfDate(props.router.params.reportdate);
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
            <h1>{t('receivesreports')}</h1>

            <div class="filters">
                {t('date')}: <DatePicker locale="nl" dateFormat="yyyy-MM-dd" selected={currentdate} onChange={(data, e) => goToDate(data)} />
                <div id="buttons" style={{ float: 'right' }}></div>
            </div>

            <div className="DataTable_Container">
                <table id="DataTable" class="dataTable-top showHead table responsive nowrap" width="100%">
                    <thead>
                        <tr>
                            <th>{t('object')}</th>
                            <th>{t('date')}</th>
                            <th>{t('team')}</th>
                            <th>{t('receivedby')}</th>
                            <th>{t('receivedon')}</th>
                            <th>{t('duration')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.map((report) => {
                            const teammembers = report.teams.flatMap(team => team.receivedby);

                            return (
                                <tr>
                                    <td valign='top' style={{ verticalAlign: 'middle', paddingTop: '0 !important' }}>{report.name} ({report.objectalarmid})</td>
                                    <td valign='top' style={{ verticalAlign: 'middle', paddingTop: '0 !important' }}>{moment(report.uptriggered).format('DD-MM-YYYY HH:mm:ss')}</td>
                                    <td valign='top' style={{ verticalAlign: 'middle', paddingTop: '0 !important' }}>
                                        {report.teams.map((team, i) => {
                                            return (<div>{team.name}</div>);
                                        })}
                                    </td>
                                    <td valign='top' style={{ verticalAlign: 'middle', paddingTop: '0 !important' }}>
                                        {teammembers.map((member, i) => {
                                            return <div>{member.name}</div>;
                                        })}
                                    </td>
                                    <td valign='top' style={{ verticalAlign: 'middle', paddingTop: '0 !important' }}>
                                        {teammembers.map((member, i) => {
                                            return <div>{moment(member.responsedon).format('DD-MM-YYYY HH:mm:ss')}</div>;
                                        })}
                                    </td>
                                    <td valign='top' style={{ verticalAlign: 'middle', paddingTop: '0 !important' }}>
                                        {teammembers.map((member, i) => {
                                            var dateUp = moment(report.uptriggered);
                                            var dateRes = moment(member.responsedon);
                                            var diff = moment.utc(moment(dateRes, "DD/MM/YYYY HH:mm:ss").diff(moment(dateUp, "DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
                                            return <div>{diff}</div>;
                                        })}
                                    </td>
                                </tr>)
                            })}
                    </tbody>
                </table>
            </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(Receives);