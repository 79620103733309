import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import { useNavigate } from 'react-router';
import LocationAlert from '../../components/LocationAlert';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const EmployeesTeam = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [employees, setEmployees] = useState([]);
    const [team, setTeam] = useState([]);

    const getData = async () => {
        const [teamsData, functions] = await Promise.all([
            import("../../data/teams.js"),
            import("../../Functions.js")
        ])

        if (localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '') {
            const getTeam = await teamsData.getTeamByID(props.router.params.id);
            const getTeamEmployees = await teamsData.getEmployeesOfTeam(props.router.params.id);
            console.log(getTeam, getTeamEmployees);
            setTeam(getTeam[0])
            setEmployees(getTeamEmployees)
            functions.initDataTable();
        }
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <h1><button className="goBackBtn" onClick={() => navigate('/employees')}><i class="fas fa-arrow-circle-left"></i></button> {team.name}</h1>
                <div class="DataTable_Container">
                    <table id="DataTable" class="showHead table responsive nowrap">
                        <thead>
                            <tr class="rowtitle">
                                <th>{t('user')}</th>
                                <th>{t('loggedin')}</th>
                                <th>{t('devicenumber')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {employees.map((employee, i) => {
                            return (
                                <tr>
                                    <td>{employee.user}</td>
                                    <td>{moment(employee.logintime).format('DD-MM-YYYY HH:mm:ss')}</td>
                                    <td>{employee.phonenumber}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(EmployeesTeam);
