import Config from "../config.json";

export const getHeaderTeams = () => {
  return fetch(Config.API_URL + "/team/get", {
    "method": "GET",
    "headers": {
        "Authorization": "Bearer " + localStorage.getItem('id_token'),
        "Content-Type": "application/x-www-form-urlencoded",
        "userid": localStorage.getItem('UserID'),
        "customerid": localStorage.getItem('CustomerID'),
        "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : '',
        "showHidden": false
    }
  })
    .then(response => response.json())
    .then(data => {
        return data;
    })
}

export const getAllTeams = () => {
  return fetch(Config.API_URL + "/mba/team/all", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "showHidden": true
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getTeams = (teamid) => {
  var url = '';
  teamid ? url = Config.API_URL + `/mba/team/get/${teamid}` : url = Config.API_URL + "/mba/team/get/";
  return fetch(url, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "teamid": teamid ? teamid : '',
      "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : '',
      "showHidden": true
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getTeamByID = (teamid) => {
  return fetch(Config.API_URL + `/mba/team/get/${teamid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "teamid": teamid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getTeamEmployees = (teamid) => {
  return fetch(Config.API_URL + `/mba/team/employees/get/${teamid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getEmployeesOfTeam = (teamid) => {
  return fetch(Config.API_URL + `/mba/employee/team/${teamid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "teamid": teamid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const addTeam = (name, queue, locationid) => {
  return fetch(Config.API_URL + `/mba/team/insert/`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "name": name,
      "queue": queue,
      "locationid": locationid,
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const delTeam = (teamid, name) => {
  return fetch(Config.API_URL + `/mba/team/delete/${teamid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "teamid": teamid,
      "name": name,
    }
  })
  .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const updateTeam = (teamid, name, locationid, queue) => {
  return fetch(Config.API_URL + `/mba/team/update/${teamid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "teamid": teamid,
      "name": name,
      "locationid": locationid,
      "queue": queue
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const switchVisibility = (switchvalue, teamid, teamname) => {
  return fetch(Config.API_URL + `/mba/team/get/${teamid}/invisible`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "teamid": teamid,
      "teamname": teamname,
      "switchvalue": switchvalue
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const switchDefaultOn = (switchvalue, teamid, teamname) => {
  return fetch(Config.API_URL + `/mba/team/get/${teamid}/defaulton`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "teamid": teamid,
      "teamname": teamname,
      "switchvalue": switchvalue
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const subscribeTeam = (teamid, teamname) => {
  return fetch(Config.API_URL + "/team/add", {
    "method": "GET",
    "headers": {
        "Authorization": "Bearer " + localStorage.getItem('id_token'),
        "Content-Type": "application/x-www-form-urlencoded",
        "userid": localStorage.getItem('UserID'),
        "customerid": localStorage.getItem('GUID'),
        "teamid": teamid,
        "teamname": teamname
    }
  })
}

export const unsubscribeTeam = (teamid, teamname) => {
  return fetch(Config.API_URL + "/team/remove", {
    "method": "GET",
    "headers": {
        "Authorization": "Bearer " + localStorage.getItem('id_token'),
        "Content-Type": "application/x-www-form-urlencoded",
        "userid": localStorage.getItem('UserID'),
        "customerid": localStorage.getItem('GUID'),
        "teamid": teamid,
        "teamname": teamname
    }
  })
}