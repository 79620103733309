import React, { useEffect, useState } from 'react';
import Menu from './components/Menu';
import { checkMBAUserPermission, crudAlertControl } from './Functions';
import withRouter from './components/withRouter';

const StatusDetail = (props) => {
    
    const [status, setStatus] = useState([]);
    const [config, setConfig] = useState([]);

    const updateStatus = async (id, event) => {
        event.preventDefault();
        const status = await import("./data/Status");

        for (var i = 0; i < event.target.length; i++) {
            if (event.target[i].dataset.id && event.target[i].dataset.id !== '') {
                const data = await status.updateConfig(id, event.target[i].dataset.id, event.target[i].value);
                crudAlertControl('update', data);
            }
        }
    }

    const getData = async () => {
        const status = await import("./data/Status");

        const data = await status.getStatus(props.router.params.id);
        const statusconfig = await status.getConfigOfStatus(props.router.params.id);

        setStatus(data[0]);
        setConfig(statusconfig);
    }

    useEffect(() => {
        checkMBAUserPermission();
        getData();
    }, [])

    return (
        <React.Fragment>
            <Menu />
            <form method="POST" onSubmit={(event) => updateStatus(props.router.params.id, event)}>
                <input className="btn btn-save" style={{ marginTop: '0px', color: '#fff' }} type="submit" name="submit" value="Opslaan" />
                <h2>{status && status.name ? status.name : 'Status details'}</h2>

                <div className="alert alert-success alert-update" role="alert">
                    Configuratie gewijzigd.
                </div>

                <div class="alert alert-danger" role="alert">
                    Er is iets niet goed gegaan. Probeer het later nog eens.
                </div>

                <span className='mbaspan'>Api</span>

                <div className="DataTable_Container">
                    <table id="DataTable" className="table responsive nowrap" width="100%">
                        <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <body>
                            {config ? config.map(data => {
                                if (data.section === 'Api') {
                                    return (
                                        <tr>
                                            <td>{data.parameter ? data.parameter : ''}</td>
                                            <td><input type="text" name={data.parameter ? data.parameter : ''} data-id={data.configid ? data.configid : ''} id={data.parameter ? data.parameter : ''} defaultValue={data.value ? data.value : ''} className="form-control" /></td>
                                        </tr>
                                    )
                                }
                            }) : ''}
                        </body>
                    </table>
                </div>

                <span className='mbaspan'>General</span>

                <div className="DataTable_Container">
                    <table id="DataTable" className="table responsive nowrap" width="100%">
                        <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <body>
                            {config ? config.map(data => {
                                if (data.section === 'General') {
                                    return (
                                        <tr>
                                            <td>{data.parameter ? data.parameter : ''}</td>
                                            <td><input type="text" name={data.parameter ? data.parameter : ''} data-id={data.configid ? data.configid : ''} id={data.parameter ? data.parameter : ''} defaultValue={data.value ? data.value : ''} className="form-control" /></td>
                                        </tr>
                                    )
                                }
                            }) : ''}
                        </body>
                    </table>
                </div>
            </form>
        </React.Fragment>
    )
}

export default withRouter(StatusDetail);