import React, { useEffect, useState } from 'react';
import withAuth from '../components/Login/withAuth';
import Menu from './components/Menu';
import { checkMBAUserPermission, crudAlertControl } from './Functions';
import withRouter from '../components/withRouter';

const ResponseRulesAdd = (props) => {

	const [clusters, setClusters] = useState([]);
	const [routes, setRoutes] = useState([]);
	const [devices, setDevices] = useState([]);
	const [objects, setObjects] = useState([]);
	const [messages, setMessages] = useState([]);
	const [incmsg, setIncMsg] = useState([]);
	const [connections, setConnections] = useState([]);
	const [timetables, setTimetables] = useState([]);

	const addRule = async (event) => {
		event.preventDefault();
		var confCluster = event.target.cluster.value;
		var confTimetable = event.target.timetable.value;
		var confTimetableUp = event.target.timetable_up.value;
		var confTimetableDown = event.target.timetable_down.value;
		var confEnabled = event.target.enabled.checked ? "1" : "0";
		var confActive = event.target.active.checked ? "1" : "0";
		var confConnectionIn = event.target.connection_in.value;
		var confMessageIn = event.target.message_in.value;
		var confDeviceIn = event.target.device_in.value;
		var confObjectIn = event.target.object_in.value;
		var confConnectionOut = event.target.connection_out.value;
		var confMessageOut = event.target.message_out.value;
		var confRouteOut = event.target.route_out.value;
		var confPriority = event.target.priority.value;

		var responserules = await import("./data/ResponseRules");
		var data = await responserules.insertResponseRules(confConnectionIn, confMessageIn, confObjectIn,confDeviceIn, confConnectionOut, confMessageOut, confRouteOut, confActive, confTimetable, confCluster, confPriority, confEnabled, confTimetableUp, confTimetableDown);
		crudAlertControl('add', data);

		setTimeout(() => {
			window.location.href = "/mba-admin/responserules";
		}, 1000);
	}

	const getData = async () => {
		const [responserules, clusters, gettimetables, routes, devices, objects, organisation] = await Promise.all([
			import("./data/ResponseRules"),
			import("./data/Clusters"),
			import("./data/Timetables"),
			import("../data/routes"),
			import("../data/devices"),
			import("../data/objects"),
			import("../data/organisation")
		])

		const conndata = await responserules.getConnections();
		const clusterdata = await clusters.getRuleClusters();
		const timetablesdata = await gettimetables.getTimetables();
		const getRoutes = await routes.getRoutes();
		const getDevices = await devices.getDevices();
		const getObjects = await objects.getObjects();
		const msg = await organisation.getOutGoingMessages();
		const incmsg = await organisation.getIncomingMessages();
		setConnections(conndata);
		setClusters(clusterdata);
		setTimetables(timetablesdata);
		setRoutes(getRoutes);
		setDevices(getDevices);
		setObjects(getObjects);
		setMessages(msg);
		setIncMsg(incmsg);
	}

	useEffect(() => {
		checkMBAUserPermission();
		getData();
	}, [])

	return(
		<React.Fragment>
	        <Menu />

			<div className="alert alert-success alert-add" role="alert">
                Responserule aangemaakt.
            </div>

            <div className="alert alert-danger" role="alert">
                Er is iets niet goed gegaan. Probeer het later nog eens.
            </div>

			<form onSubmit={(event) => addRule(event)}>
			<input style={{marginTop: '0px', marginBottom: '20px'}} type="submit" name="submit" value="Toevoegen" className='btn btn-save' />
	        <h2>General</h2>
			<div className="DataTable_Container">
                <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                    <thead>
                        <tr className="rowtitle">
							<th>Cluster</th>
							<th>Timetable</th>
                            <th>Timetable up trigger</th>
                            <th>Timetable down trigge</th>
							<th>Enabled</th>
							<th>Active</th>
                        </tr>
                    </thead>
                    <tbody>
						<tr>
							<td>
								<select id="cluster" key={`${Math.floor((Math.random() * 1000))}-min`} name="cluster" className='form-control'>
									<option value="0">Selecteer cluster</option>
									{clusters.map((cluster, i) => {
										return <option key={i} value={cluster.id}>{cluster.name}</option>
									})}
								</select>
							</td>
							<td>
								<select id="timetable" key={`${Math.floor((Math.random() * 1000))}-min`} name="timetable" className='form-control'>
									<option value="0">Selecteer timetable</option>
									{timetables.map((timetable, i) => {
										return <option key={i} value={timetable.id}>{timetable.name}</option>
									})}
								</select>
							</td>
							<td>
								<select id="timetable_up" key={`${Math.floor((Math.random() * 1000))}-min`} name="timetable_up" className='form-control'>
									<option value="0">Selecteer trigger</option>
									{messages.map((msg, i) => {
										return <option key={i} value={msg.id}>{msg.name}</option>
									})}
								</select>
							</td>
							<td>
								<select id="timetable_down" key={`${Math.floor((Math.random() * 1000))}-min`} name="timetable_down" className='form-control'>
									<option value="0">Selecteer trigger</option>
									{messages.map((msg, i) => {
										return <option key={i} value={msg.id}>{msg.name}</option>
									})}
								</select>
							</td>
							<td>
								<label className='switch'>
									<input key={`${Math.floor((Math.random() * 1000))}-min`} name="enabled" id="enabled" type='checkbox' />
									<span className='slider round'></span>
								</label>
							</td>
							<td>
								<label className='switch'>
									<input key={`${Math.floor((Math.random() * 1000))}-min`} name="active" id="active" type='checkbox' />
									<span className='slider round'></span>
								</label>
							</td>
						</tr>
                    </tbody>
                </table>
            </div>
	        
	        <h2 style={{marginBottom: '20px'}}>Incoming</h2>
			<div className="DataTable_Container">
                <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                    <thead>
                        <tr className="rowtitle">
                            <th>Incoming connection</th>
                            <th>Incoming message</th>
                            <th>Incoming device</th>
                            <th>Incoming object</th>
                        </tr>
                    </thead>
                    <tbody>
						<tr>
							<td>
								<select id="connection_in" key={`${Math.floor((Math.random() * 1000))}-min`} name="connection_in" className='form-control'>
									<option value="0">Selecteer connection</option>
									{connections.map((connection, i) => {
										return <option key={i} value={connection.id}>{connection.name}</option>;
									})}
								</select>
							</td>
							<td>
								<select id="message_in" key={`${Math.floor((Math.random() * 1000))}-min`} name="message_in" className='form-control'>
									<option value="0">Selecteer message</option>
									{incmsg.map((msg, i) => {
										return <option key={i} value={msg.id}>{msg.name}</option>
									})}
								</select>
							</td>
							<td>
								<select id="device_in" key={`${Math.floor((Math.random() * 1000))}-min`} name="device_in" className='form-control'>
									<option value="0">Selecteer device</option>
									{devices.map((device, i) => {
										return <option key={i} value={device.deviceid}>{device.devicename}</option>;
									})}
								</select>
							</td>
							<td>
								<select id="object_in" key={`${Math.floor((Math.random() * 1000))}-min`} name="object_in" className='form-control'>
									<option value="0">Selecteer object</option>
									{objects.map((object, i) => {
										return <option key={i} value={object.objectid}>{object.objectname}</option>;
									})}
								</select>
							</td>
						</tr>
                    </tbody>
                </table>
            </div>

	        <h2 style={{marginBottom: '20px'}}>Outgoing</h2>
	        <div className="DataTable_Container">
                <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                    <thead>
                        <tr className="rowtitle">
                            <th>Outgoing conection</th>
                            <th>Outgoing message</th>
                            <th>Outgoing route</th>
                            <th>Outgoing priority</th>
                        </tr>
                    </thead>
                    <tbody>
						<tr>
							<td>
								<select id="connection_out" key={`${Math.floor((Math.random() * 1000))}-min`} name="connection_out" className='form-control'>
									<option value="0">Selecteer connectie</option>
									{connections.map((connection, i) => {
										return <option key={i} value={connection.id}>{connection.name}</option>;
									})}
								</select>
							</td>
							<td>
								<select id="message_out" key={`${Math.floor((Math.random() * 1000))}-min`} name="message_out" className='form-control'>
									<option value="0">Selecteer message</option>
									{messages.map((msg, i) => {
										return <option key={i} value={msg.id}>{msg.name}</option>
									})}
								</select>
							</td>
							<td>
								<select id="route_out" key={`${Math.floor((Math.random() * 1000))}-min`} name="route_out" className='form-control'>
									<option value="0">Selecteer route</option>
									{routes.map((route, i) => {
										return <option key={i} value={route.routeid}>{route.routename}</option>;
									})}
								</select>
							</td>
							<td>
								<input type="text" id="priority" name="priority" className='form-control' />
							</td>
						</tr>
                    </tbody>
                </table>
            </div>
			</form>

        </React.Fragment>
	)
}

export default withRouter(ResponseRulesAdd);